<template>
  <b-modal :id="`delete-${prize.id}`" title="Delete Prize" @ok="handleOk" ok-title="Delete" ok-variant="success">
    <div v-if="loading" class="flex justify-center">
      <b-spinner label="Loading..." />
    </div>
    <Alert v-else variant="yellow" icon="exclamation">
      You are about to delete the prize: <strong>{{ prize.name }}</strong>
    </Alert>
    <Alert v-if="errorMessage" variant="red" icon="exclamation">{{ errorMessage }}</Alert>
  </b-modal>
</template>

<script>
import Alert from '@/components/ui/Alert';

export default {
  components: {
    Alert
  },
  props: {
    prize: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: null
    };
  },
  methods: {
    handleOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        await this.$store.dispatch('deletePrize', this.prize.id);
      } catch (error) {
        this.errorMessage = error;
      }

      this.$nextTick(() => {
        this.loading = false;
        this.$bvModal.hide(`delete-${this.prize.id}`);
      });
    }
  }
};
</script>
