var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GenericModal',{attrs:{"title":_vm.getTitle,"size":"sm","config":_vm.modalConfig,"errorMessage":_vm.errorMessage},on:{"modal-closed":_vm.modalClosed},scopedSlots:_vm._u([{key:"openButton",fn:function(ref){
var openModal = ref.openModal;
return [_c('div',{staticClass:"text-right"},[_c('LoadingButton',{staticClass:"w-26 whitespace-nowrap",attrs:{"variant":"outline","disabled":_vm.disabled},nativeOn:{"click":function($event){return openModal($event)}}},[_vm._v("Drop Cash")])],1)]}},{key:"footer",fn:function(ref){
var closeModal = ref.closeModal;
return [_c('RbButton',{staticClass:"mr-2",attrs:{"variant":"secondary"},nativeOn:{"click":function($event){return _vm.closeModalHandler(closeModal)}}},[_vm._v(" Cancel ")]),_c('LoadingButton',{attrs:{"variant":"green"},nativeOn:{"click":function($event){return _vm.submit(closeModal)}}},[_vm._v("Save")])]}}])},[[_c('div',{staticClass:"p-2"},[_c('p',{staticClass:"mb-4 text-base"},[_vm._v("Return cash from float.")]),_c('ValidationObserver',{ref:"observer"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('ValidationProvider',{attrs:{"rules":{ min_value: 1, max_value: 999999, integer: true, required: true },"name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"mb-4 text-base",attrs:{"label-for":"amount"}},[_c('b-form-input',{staticStyle:{"max-width":"15rem"},attrs:{"name":"amount","state":errors[0] ? false : valid ? true : null,"type":"number","placeholder":"0","aria-describedby":"amount-feedback","data-vv-as":"Cash Drop Amount","trim":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }