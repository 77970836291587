<template>
  <div class="mb-4 prize-list-item">
    <div
      class="flex flex-nowrap items-center min-h-20 rounded-lg bg-white border border-neutral-200 prize-list-item__main"
      v-if="!loadingWinners"
    >
      <div class="p-4 cursor-move prize-list-item--grip">
        <FontAwesomeIcon class="text-2xl" :icon="['far', 'grip-dots-vertical']" />
      </div>

      <div
        class="
          w-6/12
          xxl:w-7/12
          py-4
          pr-4
          flex flex-col
          xl:flex-row
          items-start
          xl:items-center
          mr-auto
          prize-list-item__titles
        "
      >
        <div class="xl:w-4/12 pr-4 prize-list-item__title">
          <strong
            >{{ prize.name }}
            <span class="ml-3">
              <RbBadge
                v-if="prize.quantity > 1"
                variant="tertiary"
                :label="prize.quantity.toString()"
                class="mr-auto" /></span
          ></strong>
        </div>

        <div class="xl:w-8/12">{{ prize.prizeValue }}</div>
      </div>

      <div class="w-6/12 xxl:w-5/12 py-4 flex">
        <div class="flex justify-between items-center mr-auto">
          <PrizeWinnerBadge v-if="getPrizeState(prize.id, 'confirmed')" :winner="winner" />

          <div
            v-else-if="getPrizeState(prize.id, 'pending')"
            class="bg-yellow-600 text-green-900 px-4 py-2 rounded-full prize-list-item__winner-pending"
          >
            Winner Pending
          </div>

          <div v-else>
            <EarlyBirdIcon v-if="iconDisplay" class="w-7 mr-4 inline-block" />
            <FontAwesomeIcon v-else class="text-xl text-green-600 mr-4" :icon="['far', 'gift']" />
            <DateTimeDisplay v-if="prizeDrawDate" :date-time="prizeDrawDate" :time-zone="event.timeZone" />
          </div>
        </div>

        <div class="pr-4 self-center text-center prize-list-item__controls">
          <div
            v-if="displayControls && !winner && !displayPickWinner(getPrizeWinners(prize.id), prize.drawDate)"
            class="prize-list-item__button-wrap"
          >
            <button
              @click="edit(prize.id)"
              class="p-2 outline outline-offset-2 outline-1 rounded-lg prize-list-item--edit"
            >
              <FontAwesomeIcon class="text-xl" :icon="['far', 'pen-to-square']" />
            </button>

            <button @click="remove" class="p-2 focus:ring focus:ring-green-200 rounded-lg prize-list-item--delete">
              <FontAwesomeIcon class="text-xl" :icon="['far', 'trash-can']" />
            </button>

            <CreateEditPrizeModal :prizeId="prize.id" :event="event" :edit="true" />
            <DeletePrizeModal :prize="prize" />
          </div>

          <PickWinnerModal
            v-if="showPickWinnerModal"
            :prizeId="prize.id"
            :prizeQuantity="prize.quantity"
            :eventId="event.id"
          />

          <button
            v-if="getPrizeWinners(prize.id).length && prize.quantity === 1"
            class="px-4 py-1 prize-list-item--drawer-toggle"
            @click="toggleDrawer"
          >
            <FontAwesomeIcon v-if="drawerOpen" class="text-2xl" :icon="['far', 'chevron-up']" />
            <FontAwesomeIcon v-else class="text-2xl" :icon="['far', 'chevron-down']" />
          </button>

          <div class="group relative inline-block w-32 mt-3" v-if="showProgressBar(prize.id)">
            <ProgressBar
              :id="`progressBar-${prize.id}`"
              variant="green"
              heigth="small"
              :total="prize.quantity"
              :progress="prize.picksCompleted"
            />
            <b-tooltip :target="`progressBar-${prize.id}`" triggers="hover">
              {{ toolTipText }}
            </b-tooltip>
          </div>

          <div v-if="showDownloadButton">
            <DownloadFile
              :retrieveData="downloadBatchPickWinnerReport"
              @error="exportError"
              filename="winners-report.csv"
            >
              <template #default="{ downloadFile }">
                <RbButton variant="success-outline" iconVariant="faArrowDownToLine" @click.native="downloadFile"
                  >Winners</RbButton
                >
              </template>
            </DownloadFile>
          </div>
        </div>
      </div>
    </div>
    <CollapsibleDrawer :controls="false" :externalToggle="drawerOpen" :initial="false" v-if="!loadingWinners">
      <PrizeDrawerContent
        v-if="!loadingWinners"
        :prize="prize"
        :time-zone="event.timeZone"
        :winners="getPrizeWinners(prize.id)"
      />
    </CollapsibleDrawer>
  </div>
</template>

<script>
import CollapsibleDrawer from '@/components/ui/CollapsibleDrawer';
import CreateEditPrizeModal from '@/components/modals/CreateEditPrizeModal';
import DateTimeDisplay from '@/components/ui/DateTimeDisplay';
import DeletePrizeModal from '@/components/modals/DeletePrizeModal';
import EarlyBirdIcon from '@/components/ui/EarlyBirdIcon';
import PickWinnerModal from '@/components/modals/PickWinnerModal';
import PrizeDrawerContent from '@/components/prizes/PrizeDrawerContent';
import PrizeWinnerBadge from '@/components/prizes/PrizeWinnerBadge';
import ProgressBar from '@/components/ui/ProgressBar';
import DownloadFile from '@/components/DownloadFile';
import WinnerServiceV2 from '@/lib/winner-service-v2';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

import { mapGetters } from 'vuex';

export default {
  components: {
    CollapsibleDrawer,
    CreateEditPrizeModal,
    DateTimeDisplay,
    DeletePrizeModal,
    EarlyBirdIcon,
    PickWinnerModal,
    PrizeDrawerContent,
    PrizeWinnerBadge,
    ProgressBar,
    DownloadFile
  },
  props: {
    prize: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      drawerOpen: false,
      loadingWinners: true
    };
  },
  async mounted() {
    this.loadingWinners = false;
  },
  computed: {
    prizeDrawDate() {
      return this.prize.drawDate ? this.prize.drawDate : this.event.endDate;
    },
    iconDisplay() {
      // Test if prize is early bird
      return this.isEarlyBird(this.prize.id, this.event.endDate);
    },
    winner() {
      const prizeWinners = this.getPrizeWinners(this.prize.id);

      const winner = prizeWinners.find((winner) => winner.status === 'confirmed');

      if (winner) {
        return {
          name: winner.order.name,
          ticketNumber: winner.ticket.fullTicketNumber
        };
      }

      return false;
    },
    showPickWinnerModal() {
      return (
        this.displayPickWinner(this.getPrizeWinners(this.prize.id), this.prize.drawDate) &&
        this.prize.picksCompleted !== this.prize.quantity &&
        this.prize.picksPending === 0
      );
    },
    showDownloadButton() {
      return this.prize.quantity > 1 && this.prize.picksCompleted === this.prize.quantity;
    },
    toolTipText() {
      return `${Math.floor((this.prize.picksCompleted / this.prize.quantity) * 100)}% (${this.prize.picksCompleted} / ${
        this.prize.quantity
      })`;
    },
    ...mapGetters([
      'displayPickWinner',
      'isEarlyBird',
      'getWinners',
      'getPrizeWinners',
      'getPrizeState',
      'showProgressBar'
    ])
  },
  methods: {
    edit() {
      this.$bvModal.show(`edit-prize-${this.prize.id}`);
    },
    remove() {
      this.$bvModal.show(`delete-${this.prize.id}`);
    },
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    async displayControls() {
      if (this.prizeState('pending')) {
        return false;
      }

      // Display controls for those with permission
      if (await featureEnabled(LaunchDarklyKeys.PrizeDrawsPrizeControls)) {
        return true;
      }

      return false;
    },
    downloadBatchPickWinnerReport() {
      return WinnerServiceV2.listWinners(this.event.id, { prizeId: this.prize.id }, 'text/csv');
    },
    exportError(error) {
      this.$emit('dowloadError', error);
    }
  }
};
</script>
