<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap" v-if="canEditRaffle">
      <div class="d-inline-flex">
        <CreateEventMemberModal :event="event" @created="eventMemberCreated" class="mr-3" />
        <EventMembersImportModal :event="event" />
        <b-form-input v-model="search" placeholder="Enter search" debounce="300" class="ml-3 h-100"></b-form-input>
      </div>

      <div>
        <div class="send-link-container d-flex justify-content-end">
          <DownloadFile
            :retrieveData="exportEventMembers"
            filename="event-members.csv"
            @error="exportError"
            class="mr-3"
          >
            <template #default="{ downloadFile }">
              <RbButton variant="secondary-outline" @click.native="downloadFile"> Export </RbButton>
            </template>
          </DownloadFile>
          <SendEventMemberLinkModal :event="event" />
          <div class="ml-3">
            <ActivateAllEventMembersModal
              v-if="canActivateAllEventMembers"
              :event="event"
              @activateAll="refreshTable"
            />
          </div>
          <div class="ml-3">
            <RbButton variant="danger-outline" @click.native="deactivateAll"> Deactivate All </RbButton>
          </div>
          <div v-if="isRbAdmin" class="ml-3">
            <RbButton variant="danger-outline" @click.native="deleteAll"> Delete All </RbButton>
          </div>
        </div>
      </div>
    </div>

    <div class="alert-container">
      <b-alert v-if="errorMessage" show dismissible variant="danger" class="mb-2">
        {{ errorMessage }}
      </b-alert>
      <b-alert :show="successDuration" @dismissed="successDuration = 0" dismissible fade variant="success" class="mb-2">
        {{ successMessage }}
      </b-alert>
    </div>
    <div class="pb-8">
      <b-form inline v-if="canEditRaffle">
        <b-checkbox name="show-disabled" v-model="showInactive" switch />
        <label for="show-disabled">Show Inactive</label>
      </b-form>
    </div>

    <GenericTable
      :columns="columns"
      :data="data"
      :pagination="pagination"
      :loading="loading"
      :actions="true"
      @updateCurrentPage="updateCurrentPage"
    >
      <template v-if="this.canEditRaffle" #actions="{ row }">
        <ActionMenu class="absolute self-center">
          <Action v-b-modal="row.id" icon="edit">Edit</Action>
          <Action v-if="row.active && row.email" icon="paper-plane" @click="sendLink(row)"> Send Link </Action>
          <b-dropdown-divider />
          <Action v-if="row.active" variant="danger" icon="trash-alt" @click="confirmDeactivate(row)">
            Deactivate
          </Action>
          <Action v-if="!row.active" variant="danger" icon="trash-alt" @click="confirmDelete(row)"> Delete </Action>
        </ActionMenu>
        <EditEventMemberModal @onEdited="onEditEventMember" :modalId="row.id" :eventMember="row" :event="event" />
      </template>

      <template #active="{ row }">
        <div class="text-right">
          <span v-if="row.active"><RbBadge variant="success" label="Active" /></span>
          <span v-else><RbBadge variant="warning" label="Inactive" /></span>
        </div>
      </template>
    </GenericTable>
  </div>
</template>
<script>
import EventServiceV2 from '@/lib/event-service-v2';
import EventMemberServiceV2 from '@/lib/event-member-service-v2';
import EditEventMemberModal from '@/components/EditEventMemberModal.vue';
import CreateEventMemberModal from '@/components/CreateEventMemberModal.vue';
import EventMembersImportModal from '@/components/EventMembersImportModal.vue';
import SendEventMemberLinkModal from '@/components/SendEventMemberLinkModal.vue';
import ActionMenu from '@/components/ActionMenu.vue';
import Action from '@/components/ActionMenuAction.vue';
import MessageCenter from '@/lib/message-center';
import DownloadFile from './DownloadFile.vue';
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import GenericTable from '@/components/GenericTable.vue';
import ActivateAllEventMembersModal from '@/components/modals/ActivateAllEventMembersModal.vue';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  props: ['event'],
  components: {
    EditEventMemberModal,
    CreateEventMemberModal,
    EventMembersImportModal,
    SendEventMemberLinkModal,
    ActionMenu,
    Action,
    DownloadFile,
    GenericTable,
    ActivateAllEventMembersModal
  },
  data() {
    return {
      eventMembers: [],
      usedNumbers: [],
      errorMessage: null,
      successMessage: null,
      successDuration: 0,
      showInactive: false,
      sendingLink: false,
      search: '',
      isRbAdmin: false,
      canEditRaffle: false,
      data: [],
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0,
        sortDir: 'desc'
      },
      loading: false,
      canActivateAllEventMembers: false
    };
  },
  async created() {
    const sessionUser = await getAuth().sessionUser();
    // Render of `<template />` is sync. Therefore we save the role in the component’s data, then render that data.
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
    this.getEventMembers();

    const search = this.$route.query.search;
    if (search) {
      this.form.input = search;
    }
  },
  async mounted() {
    this.canActivateAllEventMembers = await featureEnabled(LaunchDarklyKeys.ActivateAllEventMembers);
    this.canEditRaffle = await featureEnabled(LaunchDarklyKeys.EditRaffle);
    this.loadData();
  },
  computed: {
    columns() {
      const fields = [
        { name: 'number', label: '#', classes: 'w-auto' },
        { name: 'name', label: 'Name', classes: 'w-auto' },
        { name: 'email', label: 'Email', classes: 'w-auto' },
        { name: 'active', label: '', classes: 'w-auto' }
      ];

      return fields;
    },
    form() {
      return this.$store.getters.getForm;
    }
  },
  watch: {
    showInactive: function () {
      this.refreshTable();
    },

    search: function () {
      this.refreshTable();
    },
    'form.input': function () {
      if (this.form.input?.trim().length >= 3) {
        this.refreshTable();
      }
    },
    form: {
      handler() {
        this.refreshTable();
      },
      deep: true
    }
  },
  methods: {
    updateSearch(input) {
      this.$store.commit('setSearchInput', input);
    },
    async exportError(error) {
      this.message = this.parseError(error).message;
    },

    exportEventMembers() {
      return EventServiceV2.exportEventMembers(this.event.id);
    },

    displaySuccess(message) {
      this.successMessage = message;
      this.successDuration = 4;
    },

    async sendLink(eventMember) {
      const message = `Are you sure you want to send a link to ${eventMember.name}?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        try {
          await MessageCenter.sendEventMemberLink(this.event.id, eventMember.id);
          this.displaySuccess(`Successfully sent link to ${eventMember.name}.`);
          this.modalShow = false;
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async deactivateAll() {
      const message = `Are you sure you want to deactivate all "In support of" for this raffle?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);
      if (confirm) {
        this.errorMessage = null;

        try {
          await EventServiceV2.updateMultipleEventMembers({ eventId: this.event.id, active: false });
          this.refreshTable();
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async deleteAll() {
      const message = `Are you sure you want to delete all "In support of" for this raffle?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        this.errorMessage = null;

        try {
          await EventMemberServiceV2.deleteEventMember(this.event.id);
          this.refreshTable();
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    },
    async confirmDeactivate(row) {
      const eventMember = row;
      const message = `Are you sure you want to deactivate ${eventMember.name}?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        this.deactivateEventMember(eventMember);
      }
    },
    async confirmDelete(row) {
      const eventMember = row;
      const message = `Are you sure you want to permanently delete ${eventMember.name}?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        this.deleteEventMember(eventMember);
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return;
      }

      if (!item.active) {
        return 'inactive-member';
      }
    },
    onEditEventMember() {
      this.refreshTable();
    },
    async refreshTable() {
      this.errorMessage = null;
      this.getEventMembers();
      await this.loadData();

      this.$root.$emit('bv::refresh::table', 'event-members-table');
    },
    inactiveToggled() {
      this.$nextTick(() => {
        this.refreshTable();
      });
    },
    async deactivateEventMember(eventMember) {
      this.errorMessage = null;

      try {
        await EventServiceV2.updateEventMember(eventMember.id, { active: false });
        this.displaySuccess(`Deactivated ${eventMember.name} successfully.`);
        this.refreshTable();
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },

    async deleteEventMember(eventMember) {
      this.errorMessage = null;

      try {
        await EventServiceV2.deleteEventMember(eventMember.id);
        this.displaySuccess(`Deleted ${eventMember.name} successfully.`);
        this.refreshTable();
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    eventMemberCreated() {
      this.refreshTable();
    },
    async getEventMembers() {
      if (this.event.id) {
        // load the list of event members
        this.loading = true;

        try {
          const listParams = {
            eventId: this.event.id,
            page: this.pagination.page,
            pageSize: 10,
            sortBy: this.pagination.sortBy || undefined,
            sortDir: this.sortDir
          };

          if (this.search) {
            listParams.search = this.search;
          }

          if (this.showInactive) {
            listParams.includeInactive = true;
          }
          const { data, pagination } = await EventServiceV2.listEventMembers(listParams);
          const dataArr = [];
          for (const eventMember of data) {
            const item = {
              id: eventMember.id,
              name: eventMember.name,
              email: eventMember.email,
              number: eventMember.number,
              active: eventMember.active
            };

            dataArr.push(item);
          }
          this.pagination = pagination;
          this.data = dataArr;

          this.loading = false;
        } catch (error) {
          this.$root.$emit('error', error);
        }
      }

      return [];
    },
    async sort(sortBy, sortDir) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      await this.getEventMembers();
    },
    async updateCurrentPage(page) {
      this.pagination.page = page;
      await this.getEventMembers();
    }
  }
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
.status-column {
  width: 3em;
}
.alert-container {
  height: 2em;
}
</style>
