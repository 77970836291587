<template>
  <div class="flex">
    <RbButton v-b-modal.pick-winner-modal variant="success" size="sm" :disabled="disabled"> Pick Winner </RbButton>

    <span
      id="winner-information"
      class="winner-information"
      v-b-tooltip.hover.right="'Winner will be picked using Rafflebox GLI certified Random Number Generation (RNG).'"
    >
      <i class="fas fa-info-circle"></i>
    </span>

    <b-modal
      id="pick-winner-modal"
      centered
      title="Pick Grand Prize Winner"
      ok-title="Pick Winner"
      ok-variant="success"
      @ok="handleOk"
      body-class="position-static"
      v-model="modalShow"
    >
      <b-overlay :show="pickingWinner" no-wrap rounded="sm" />
      <Alert variant="yellow" icon="question" class="mt-5">
        <p>
          You are about to pick the winner for the Raffle using Random Number Generator. Once a winner is selected you
          will not be able to modify this Raffle.
        </p>
        <!-- DO NOT REMOVE THIS - REQUIRED BY GLI -->
        <p class="mt-5">
          <strong
            >By clicking "Pick Winner" you have confirmed that all sales for the Raffle have been reconciled.</strong
          >
        </p>
      </Alert>
      <Alert v-if="pickWinnerError" icon="exclamation" variant="red">{{ pickWinnerError }}</Alert>
    </b-modal>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';

import WinnerService from '@/lib/winner-service-v2';

export default {
  name: 'PickWinnerRng',
  components: {
    Alert
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pickWinnerError: '',
      pickingWinner: false,
      modalShow: false
    };
  },
  methods: {
    async handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },
    async onSubmit() {
      this.pickingWinner = true;

      try {
        await WinnerService.createWinner(this.event.id);

        this.$emit('winnerPicked');

        this.modalShow = false;
      } catch (error) {
        this.pickingWinner = false;
        this.pickWinnerError = this.parseError(error).message;
      }
    }
  }
};
</script>

<style scoped>
.winner-information {
  padding-left: 0.5rem;
  vertical-align: top;
}
</style>
