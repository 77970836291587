<template>
  <div>
    <b-button v-b-modal="'activate-raffle-modal'" variant="outline-success" size="sm">
      <FontAwesomeIcon :icon="['far', 'circle-play']" />
      <span class="pl-2">Activate</span>
    </b-button>

    <b-modal
      :id="'activate-raffle-' + modalId"
      title="Activate Raffle"
      v-model="modalShow"
      @ok="handleOk"
      no-close-on-backdrop
      size="m"
      body-class="position-static"
    >
      <b-form @submit.stop.prevent="onSubmit">
        <b-overlay :show="loading" no-wrap rounded="sm" />
        <Alert v-if="!event.active" variant="yellow" icon="exclamation" show>
          Once you activate the Raffle, ticket sales will go live. To comply with local regulations, some information on
          the Raffle may be read-only.<br /><br />
          If you require any changes to the Raffle please contact
          <a href="mailto:help@rafflebox.ca">help@rafflebox.ca</a>.
        </Alert>
        <Alert v-if="errorMessage" variant="red" icon="exclamation">
          Failed to activate the raffle: {{ errorMessage }}
        </Alert>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import EventService from '@/lib/event-service-v2';
import Alert from '@/components/ui/Alert';

export default {
  props: {
    modalId: {
      type: String
    },
    event: {
      type: Object,
      default: () => {
        return {
          id: null,
          active: null
        };
      }
    }
  },
  components: {
    Alert
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      id: null,
      active: null,
      loading: false
    };
  },
  methods: {
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      this.loading = true;
      try {
        await EventService.activateEvent(this.event.id);

        this.modalShow = false;
        this.$router.go();
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
      this.loading = false;
    }
  }
};
</script>
