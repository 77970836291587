<template>
  <div>
    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">In-Venue</h1>
      <ImageUploadCropper
        :imageUrl="ivLogoUrl"
        :uploadError="invenueImageUploadError"
        :uploadSuccess="invenueImageUploadSuccess"
        @uploadImage="uploadInvenueImage"
        @clearImage="clearInvenueImage"
        imageName="ivLogo"
      />
      <ul class="mb-4">
        <li>
          <div class="flex mt-6">
            <RbToggle
              v-model="allowInVenueSales"
              :state="validateState('allow-invenue-sales')"
              name="allow-invenue-saless"
              :disabled="isDisabled('settings.allowInVenueSales')"
              label="Allow In-Venue Sales"
              variant="success"
              @change="allowInVenueSales = $event"
            />
            <sup v-b-tooltip.hover.top="'If enabled then In-Venue Sales will be allowed.'" class="mt-2 ml-2">
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>

        <li>
          <div class="flex">
            <RbToggle
              v-model="invenuePhone"
              :state="validateState('invenue-phone-switch')"
              name="invenue-phone-switch"
              :disabled="isDisabled('settings.invenuePhone')"
              label="Require Phone Number"
              variant="success"
              @change="invenuePhone = $event"
            />
            <sup
              v-b-tooltip.hover.top="'Point of Sales requires phone number to complete a sale of tickets'"
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <RbToggle
              v-model="ivRequireFirstLastName"
              :state="validateState('iv-require-first-last-name-switch')"
              name="iv-require-first-last-name-switch"
              :disabled="isDisabled('settings.ivRequireFirstLastName')"
              label="Require First Last Name"
              variant="success"
              @change="ivRequireFirstLastName = $event"
            />
            <sup
              v-b-tooltip.hover.top="'Point of Sales requires first and last name to complete a sale of tickets'"
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <RbToggle
              v-model="ivRequireEmail"
              :state="validateState('iv-require-email-switch')"
              name="iv-require-email-switch"
              :disabled="isDisabled('settings.ivRequireEmail')"
              label="Require Email"
              variant="success"
              @change="ivRequireEmail = $event"
            />
            <sup
              v-b-tooltip.hover.top="'Point of Sales requires email to complete a sale of tickets'"
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>

        <li>
          <div class="flex">
            <RbToggle
              v-model="removeCashButton"
              :state="validateState('remove-cash-button-switch')"
              name="remove-cash-button-switch"
              :disabled="isDisabled('settings.removeCashButton')"
              label="Remove Cash Button"
              variant="success"
              @change="removeCashButton = $event"
            />
            <sup
              v-b-tooltip.hover.top="'This will remove the cash purchase button from the checkout screen.'"
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <RbToggle
              v-model="removeCreditButton"
              :state="validateState('remove-credit-button-switch')"
              name="remove-credit-button-switch"
              :disabled="isDisabled('settings.removeCreditButton')"
              label="Remove Credit Button"
              variant="success"
              @change="removeCreditButton = $event"
            />
            <sup
              v-b-tooltip.hover.top="'This will remove the credit purchase button from the checkout screen.'"
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <RbToggle
              v-model="removeDebitButton"
              :state="validateState('remove-debit-button-switch')"
              name="remove-debit-button-switch"
              :disabled="isDisabled('settings.removeDebitButton')"
              label="Remove Debit Button"
              variant="success"
              @change="removeDebitButton = $event"
            />
            <sup
              v-b-tooltip.hover.top="'This will remove the debit purchase button from the checkout screen.'"
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <RbToggle
              v-model="compactPrinting"
              :state="validateState('compact-printing-switch')"
              name="compact-printing-switch"
              :disabled="isDisabled('settings.compactPrinting')"
              label="Compact Printing"
              variant="success"
              @change="compactPrinting = $event"
            />
            <sup
              v-b-tooltip.hover.top="
                'This will print the range of ticket numbers instead of each one individually. For Example: 1000 - 1200'
              "
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>
      </ul>

      <b-row v-if="showInVenueImprovements">
        <b-col>
          <b-form-group
            description='Please enter a valid website URL starting with "https://" (e.g., "https://www.example.com")'
            :invalid-feedback="veeErrors.first('ticket-footer-qr-code')"
          >
            <template #label>
              <label for="ticket-footer-qr-code" class="mb-0 pb-1">Ticket Footer QR Code</label>
              <div style="max-width: 40rem">
                <small
                  >Enter in a valid URL and click Create QR. When the QR code is generated, the Raffle can be
                  saved.</small
                >
              </div>
            </template>
            <b-input-group>
              <b-form-input
                class="input-lg-with-button"
                name="ticket-footer-qr-code"
                :state="validateState('ticket-footer-qr-code')"
                v-model="ticketFooterQRCode"
                aria-describedby="ticket-footer-qr-code"
                v-validate="{ url: { require_protocol: true, protocols: ['https'] } }"
                size="md"
                placeholder="https://your-url-here.com"
              />
              <b-input-group-append>
                <b-button @click="uploadTicketFooterQRCodeImage" variant="success">
                  <span>Create QR</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="showInVenueImprovements" class="-mt-2 mb-3">
        <b-col>
          <b-spinner v-if="ticketFooterQRCodeImageUploading" class="ml-2" small />
          <b-button
            v-if="ticketFooterQRCodeImage"
            @click="showTicketFooterQRCode = !showTicketFooterQRCode"
            size="sm"
            variant="outline-secondary"
            class="mt-1 mb-1"
            >{{ showTicketFooterQRCode ? 'Hide QR Code' : 'Show QR Code' }}</b-button
          >
          <QrCodeVue
            :value="invenueForm.ticketFooterQRCode"
            ref="ticketFooterQRCodeComponent"
            render-as="canvas"
            size="150"
            :class="{ hidden: !showTicketFooterQRCode }"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="In-Venue Ticket Footer" label-for="invenue-ticket-footer" label-class="pb-1">
            <b-form-textarea
              class="input-lg-width"
              name="invenue-ticket-footer"
              v-model="invenueTicketFooter"
              max-length="1500"
              v-validate="{}"
              aria-describedby="invenue-ticket-footer"
              size="md"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div v-if="showInVenueImprovements">
            <h1 class="text-2xl font-medium">InVenue Logo Footer</h1>
            <ImageUploadCropper
              :imageUrl="ticketFooterImage"
              :uploadError="invenueFooterImageUploadError"
              :uploadSuccess="invenueFooterImageUploadSuccess"
              @uploadImage="uploadInVenueFooterImage"
              @clearImage="clearInVenueFooterImage"
              imageName="ivLogoFooter"
            />
          </div>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import formatUrl from '@/lib/s3';
import EventServiceV2 from '@/lib/event-service-v2';
import ImageUploadCropper from '@/components/forms/ImageUploadCropper';
import QrCodeVue from 'qrcode.vue';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  components: {
    ImageUploadCropper,
    QrCodeVue
  },
  data() {
    return {
      showInVenueImprovements: false,
      editRaffleRestricted: false
    };
  },
  async mounted() {
    this.showInVenueImprovements = await featureEnabled(LaunchDarklyKeys.InVenueImprovements);
    this.editRaffleRestricted = await featureEnabled(LaunchDarklyKeys.EditRaffleRestricted);
  },
  computed: {
    ...mapFields([
      'settings.ivLogoUrl',
      'settings.allowInVenueSales',
      'settings.invenuePhone',
      'settings.ivRequireFirstLastName',
      'settings.ivRequireEmail',
      'settings.removeCashButton',
      'settings.removeCreditButton',
      'settings.removeDebitButton',
      'settings.compactPrinting',
      'invenueForm.ticketFooterQRCode',
      'invenueForm.ticketFooterQRCodeImage',
      'invenueForm.ticketFooterImage',
      'invenueForm.invenueTicketFooter',
      'invenueForm.invenueFooterImage',
      'invenueForm.invenueImageUploadError',
      'invenueForm.invenueImageUploadSuccess',
      'invenueForm.invenueFooterImageUploadError',
      'invenueForm.invenueFooterImageUploadSuccess',
      'invenueForm.ticketFooterQRCodeImageUploading',
      'invenueForm.showTicketFooterQRCode'
    ]),
    invenueForm() {
      return this.$store.getters.getInvenueForm;
    },
    settings() {
      return this.$store.getters.getSettings;
    },
    raffle() {
      return this.$store.getters.getRaffle;
    },
    status() {
      return this.$store.getters.getStatus;
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    isDisabled(element) {
      if (this.editRaffleRestricted) {
        return false;
      }

      const disabledFields = [
        'drawType',
        'startDate',
        'startTime',
        'startTicketNum',
        'startingPot',
        'minimumJackpotCents',
        'maxJackpot',
        'fractionalJackpotPercent',
        'rules',
        'licenseNumber',
        'name',
        'province',
        'category',
        'drawLocation',
        'customJackpotMessage',
        'licenseUrl'
      ];

      if (this.status === 'pending') {
        return false;
      }

      if (['active', 'ended'].includes(this.status)) {
        return disabledFields.includes(element);
      }

      if (this.status === 'closed') {
        return element !== 'shortlink';
      }

      return true;
    },
    async uploadInvenueImage(payload) {
      const file = new File([payload.blob], payload.name);

      try {
        const params = {
          fileName: payload.name
        };

        const url = await EventServiceV2.getIvLogoUrl(this.raffle.id, params);
        const ivLogoUrl = url;
        await EventServiceV2.uploadFileToS3(ivLogoUrl, file);

        this.ivLogoUrl = formatUrl(ivLogoUrl);
        await this.$store.dispatch('setInvenueForm', {
          ...this.invenueForm,
          invenueImageUploadError: null,
          invenueImageUploadSuccess: true,
          errorMessage: null,
          editRaffleFailed: false
        });
        await this.$store.dispatch('setSettings', {
          ...this.settings,
          ivLogoUrl: this.ivLogoUrl
        });
      } catch (error) {
        this.$store.dispatch('setInvenueForm', {
          ...this.invenueForm,
          invenueImageUploadError: error,
          invenueImageUploadSuccess: false,
          errorMessage: 'Could not upload invenue logo'
        });
      }
    },

    async clearInvenueImage() {
      await this.$store.dispatch('setInvenueForm', {
        ...this.invenueForm,
        invenueImageUploadError: null,
        invenueImageUploadSuccess: null
      });
      await this.$store.dispatch('setSettings', {
        ...this.settings,
        ivLogoUrl: null
      });
    },

    async uploadInVenueFooterImage(payload) {
      const file = new File([payload.blob], payload.name);

      try {
        const params = {
          fileName: payload.name
        };

        const logoUrl = await EventServiceV2.getIvLogoUrl(this.raffle.id, params);
        await EventServiceV2.uploadFileToS3(logoUrl, file);
        await this.$store.dispatch('setInvenueForm', {
          ...this.invenueForm,
          ticketFooterImage: formatUrl(logoUrl),
          invenueFooterImageUploadError: null,
          invenueFooterImageUploadSuccess: true,
          errorMessage: null,
          editRaffleFailed: false
        });
      } catch (error) {
        this.$store.dispatch('setInvenueForm', {
          ...this.invenueForm,
          invenueFooterImageUploadError: error,
          invenueFooterImageUploadSuccess: false,
          errorMessage: 'Could not upload invenue logo'
        });
      }
    },

    clearInVenueFooterImage() {
      this.$store.dispatch('setInvenueForm', {
        ...this.invenueForm,
        ticketFooterImage: null,
        invenueFooterImageUploadError: null,
        invenueFooterImageUploadSuccess: null
      });
    },

    async uploadTicketFooterQRCodeImage() {
      if (this.ticketFooterQRCode === '') {
        return;
      }

      if (!this.validateState('ticket-footer-qr-code')) {
        return;
      }
      await this.$store.dispatch('setInvenueForm', {
        ...this.invenueForm,
        ticketFooterQRCodeImageUploading: true
      });

      const blob = await new Promise((resolve) => {
        this.$refs.ticketFooterQRCodeComponent.$el.querySelector('canvas').toBlob(resolve);
      });

      const fileName = 'ticketFooterQRCode.png';
      const file = new File([blob], fileName);
      try {
        const params = {
          fileName
        };
        const url = await EventServiceV2.getS3UploadURL(this.raffle.id, params);
        await EventServiceV2.uploadFileToS3(url, file);

        this.ticketFooterQRCodeImage = formatUrl(url);

        this.editRaffleFailed = false;
        this.errorMessage = null;
      } catch (error) {
        this.editRaffleFailed = true;
        this.errorMessage = 'Could not upload ticket footer QR Code';
      }

      this.ticketFooterQRCodeImageUploading = false;
    }
  }
};
</script>
