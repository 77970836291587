<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="3" xl="3">
        <b-form-group
          label="Ticker Header"
          label-for="input-ticker-header"
          :invalid-feedback="veeErrors.first('input-ticker-header')"
          label-size="sm"
          label-class="mb-0"
        >
          <b-form-input
            name="input-ticker-header"
            v-model="tickerHeader"
            :state="validateState('input-ticker-header')"
            v-validate="{}"
            aria-describedby="input-ticker-header"
            data-vv-as="ticker header"
            size="sm"
            :disabled="isDisabled('tickerHeader')"
          />
        </b-form-group>
        <b-form-group
          label="Ticker Footer"
          label-for="input-ticker-footer"
          :invalid-feedback="veeErrors.first('input-ticker-footer')"
          label-size="sm"
          label-class="mb-0"
        >
          <b-form-input
            name="input-ticker-footer"
            v-model="tickerFooter"
            :state="validateState('input-ticker-footer')"
            v-validate="{}"
            aria-describedby="input-ticker-footer"
            data-vv-as="ticker footer"
            size="sm"
            :disabled="isDisabled('tickerfooter')"
          />
        </b-form-group>
        <b-form-group
          label="Ticker Url"
          label-for="input-ticker-url"
          :invalid-feedback="veeErrors.first('input-ticker-url')"
          label-size="sm"
          label-class="mb-0"
        >
          <b-form-input
            name="input-ticker-url"
            v-model="tickerUrl"
            :state="validateState('input-ticker-url')"
            v-validate="{}"
            aria-describedby="input-ticker-url"
            data-vv-as="ticker url"
            size="sm"
            :disabled="isDisabled('tickerUrl')"
          />
        </b-form-group>
        <b-form-group
          label="Ticker Logo"
          label-for="input-ticker-logo"
          :invalid-feedback="veeErrors.first('input-ticker-logo')"
          label-size="sm"
          label-class="mb-0"
        >
          <b-form-input
            name="input-ticker-logo"
            v-model="tickerLogo"
            :state="validateState('input-ticker-logo')"
            v-validate="{}"
            aria-describedby="input-ticker-logo"
            data-vv-as="ticker logo"
            size="sm"
            :disabled="isDisabled('tickerLogo')"
          />
        </b-form-group>
        <div class="flex">
          <RbToggle
            v-if="showInVenueImprovements"
            v-model="tickerQrCode"
            :state="validateState('ticker-qr-code')"
            :disabled="isDisabled('settings.tickerQrCode')"
            label="Display QR Code"
            variant="success"
            @change="tickerQrCode = $event"
          />
          <sup v-b-tooltip.hover.top="'Show a QR code linking to your raffle page on the ticker'" class="mt-2 ml-2">
            <span class="fas fa-info-circle"></span>
          </sup>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  computed: {
    ...mapFields([
      'tickerForm.tickerHeader',
      'tickerForm.tickerFooter',
      'tickerForm.tickerUrl',
      'tickerForm.tickerLogo',
      'settings.tickerQrCode'
    ]),
    status() {
      return this.$store.getters.getStatus;
    }
  },
  data() {
    return {
      editRaffleRestricted: false,
      showInVenueImprovements: false
    };
  },
  mounted() {
    this.editRaffleRestricted = featureEnabled(LaunchDarklyKeys.EditRaffleRestricted);
    this.showInVenueImprovements = featureEnabled(LaunchDarklyKeys.EditRaffleInVenueImprovements);
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    isDisabled(element) {
      if (this.editRaffleRestricted) {
        return false;
      }

      const disabledFields = [
        'drawType',
        'startDate',
        'startTime',
        'startTicketNum',
        'startingPot',
        'minimumJackpotCents',
        'maxJackpot',
        'fractionalJackpotPercent',
        'rules',
        'licenseNumber',
        'name',
        'province',
        'category',
        'drawLocation',
        'customJackpotMessage',
        'licenseUrl'
      ];

      if (this.status === 'pending') {
        return false;
      }

      if (['active', 'ended'].includes(this.status)) {
        return disabledFields.includes(element);
      }

      if (this.status === 'closed') {
        return element !== 'shortlink';
      }

      return true;
    }
  }
};
</script>
