<template>
  <div>
    <b-button-group>
      <b-button variant="danger" size="sm" @click="handleModal('cancel')"> Cancel </b-button>
      <b-button variant="success" size="sm" @click="handleModal('confirm')"> Confirm </b-button>
    </b-button-group>

    <b-modal
      :id="'confirm-winner-' + winner.id"
      :ref="'modal-' + winner.id"
      :title="modalTitle"
      ok-variant="success"
      @ok="handleOk"
      @hidden="resetForm"
      no-close-on-backdrop
      size="m"
      body-class="position-static"
    >
      <p>{{ modalDescription }}</p>

      <b-form>
        <b-overlay :show="busy" no-wrap rounded="sm" />
        <b-form-group label-for="input-note" :invalid-feedback="veeErrors.first('input-note')" label-class="mb-0">
          <template slot="label"> {{ formLabel }} <span class="required">*</span> </template>
          <b-form-input
            name="input-note"
            v-model="winnerNote"
            v-validate="{ required: true, max: 120 }"
            :state="validateState('input-note')"
            aria-describedby="input-note-feedback"
            :data-vv-as="modalTitle.toLowerCase()"
            trim
          />
        </b-form-group>
      </b-form>

      <Alert v-if="errorMessage" variant="red" icon="exclamation">{{ errorMessage }}</Alert>
    </b-modal>
  </div>
</template>

<script>
import WinnerService from '@/lib/winner-service-v2';
import Alert from '@/components/ui/Alert';

export default {
  props: ['winner'],
  components: {
    Alert
  },
  data() {
    return {
      busy: false,
      errorMessage: '',
      modalTitle: '',
      modalDescription: '',
      isConfirm: true,
      winnerNote: '',
      formLabel: ''
    };
  },
  methods: {
    winnerCancelled() {
      this.$emit('complete');
    },
    handleModal(type) {
      if (type === 'confirm') {
        this.isConfirm = true;
        this.modalTitle = 'Confirm Winner';
        this.formLabel = 'Note about the winner:';
      } else {
        this.isConfirm = false;
        this.modalTitle = 'Reason for Cancelling';
        this.formLabel = 'Reason for Cancellation:';
      }

      this.$refs[`modal-${this.winner.id}`].show();
    },
    async handleOk(event) {
      event.preventDefault();
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      const status = this.isConfirm ? 'confirmed' : 'cancelled';

      try {
        this.busy = true;

        const winner = await WinnerService.updateWinner(this.winner.id, {
          status: status,
          comment: this.winnerNote
        });

        this.$emit('complete', winner);
      } catch (error) {
        this.errorMessage = this.parseError(error);
      } finally {
        this.busy = false;
      }
    },
    resetForm() {
      this.errorMessage = '';
      this.winnerNote = null;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style scoped>
.required {
  color: #dc3545;
}

.rb-alert__danger {
  margin: 1rem 0 0;
  padding: 1rem;
}
</style>

<style>
.form-control-sm {
  border-radius: 0.5rem;
}
</style>
