<template>
  <div class="sm:mx-2 md:max-w-7xl">
    <div class="flex justify-center" v-if="loading && !error">
      <RbLoadingSpinner />
    </div>
    <div v-else>
      <div>
        <div class="flex mb-3 justify-between xs:items-baseline">
          <h1 class="text-2xl font-medium mb-0">Ticket Sales</h1>
          <DownloadReportButton @onClick="downloadReport" :downloadingReport="downloadingReport" />
        </div>
      </div>
      <div v-if="!loading && error">
        <Alert v-if="error" variant="red" icon="exclamation">{{ error }}</Alert>
      </div>
      <div class="max-w-full overflow-x-scroll" v-else>
        <table class="min-w-full divide-y divide-gray-300">
          <caption class="sr-only">
            Ticket Sales Report
          </caption>
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-xs tracking-wide text-gray-900 w-20">
                Series
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                # Tickets
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Price
              </th>
              <th scope="col" class="whitespace-nowrap px-6 py-3 text-right text-xs tracking-wide text-gray-900 w-1/5">
                Total Orders
              </th>
              <th scope="col" class="whitespace-nowrap px-6 py-3 text-right text-xs tracking-wide text-gray-900 w-1/5">
                Total Sales
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Total Tickets
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Limit
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Issued
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Sold Out
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="item in salesReport.items" :key="item.series">
              <td class="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {{ item.series }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ item.tickets }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ formatCurrency(item.price / 100) }}
              </td>
              <td class="whitespace-nowrap text-right px-6 py-4 text-sm text-gray-800">
                <div class="flex flex-row align-items-center justify-content-end gap-3">
                  <p>{{ item.orders }}</p>
                  <div class="w-44">
                    <ProgressBar :progress="item.orders" :total="totals.orders" variant="green" height="small" />
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap text-right px-6 py-4 text-sm text-gray-800">
                <div class="flex flex-row align-items-center justify-content-end gap-3">
                  <p>{{ formatCurrency(item.sales / 100, { precision: 0 }) }}</p>
                  <div class="w-44">
                    <ProgressBar :progress="item.sales" :total="totals.sales" variant="green" height="small" />
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ item.ticketsIssued }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ item.limit }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ item.issued }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p v-if="item.percentSold > 0">{{ item.percentSold.toFixed(2) }}%</p>
                <p v-else>-</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import ProgressBar from '@/components/ui/ProgressBar';
import DownloadReportButton from '@/components/ui/DownloadReportButton';

export default {
  components: {
    Alert,
    ProgressBar,
    DownloadReportButton
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  async mounted() {
    await this.getReport();
  },
  computed: {
    salesReport() {
      return this.$store.state.ticketSalesReport.report;
    },
    loading() {
      return this.$store.state.ticketSalesReport.loading;
    },
    downloadingReport() {
      return this.$store.state.ticketSalesReport.downloading;
    },
    error() {
      return this.$store.state.ticketSalesReport.error;
    },
    totals() {
      const sales = this.salesReport.items.reduce((acc, item) => acc + item.sales, 0);
      const orders = this.salesReport.items.reduce((acc, item) => acc + item.orders, 0);
      return {
        sales,
        orders
      };
    }
  },
  methods: {
    async getReport() {
      await this.$store.dispatch('getTicketSalesReport', this.eventId);
    },
    async downloadReport() {
      await this.$store.dispatch('downloadTicketSalesReport', this.eventId);
    }
  }
};
</script>
