<template>
  <div>
    <div class="flex justify-center" v-if="loading && !errorMessage">
      <RbLoadingSpinner />
    </div>
    <div v-if="!loading && errorMessage">
      <Alert variant="red" icon="exclamation">Error loading Sales by Seller: {{ errorMessage }}</Alert>
    </div>
    <GenericTable v-if="!loading && !errorMessage" :columns="columns" :data="data" title="Sales by Seller">
      <template v-slot:refundsCreditCents="slotProps">
        <p>{{ formatBalance(slotProps.row.refundsCreditCents) }}</p>
      </template>
      <template v-slot:refundsDebitCents="slotProps">
        <p>{{ formatBalance(slotProps.row.refundsDebitCents) }}</p>
      </template>
      <template v-slot:refundsCashCents="slotProps">
        <p>{{ formatBalance(slotProps.row.refundsCashCents) }}</p>
      </template>
      <template v-slot:refunds="slotProps">
        <p>{{ formatBalance(slotProps.row.refunds) }}</p>
      </template>
      <template v-slot:salesCreditCents="slotProps">
        <p>{{ formatBalance(slotProps.row.salesCreditCents) }}</p>
      </template>
      <template v-slot:salesDebitCents="slotProps">
        <p>{{ formatBalance(slotProps.row.salesDebitCents) }}</p>
      </template>
      <template v-slot:salesCashCents="slotProps">
        <p>{{ formatBalance(slotProps.row.salesCashCents) }}</p>
      </template>
      <template v-slot:total="slotProps">
        <p>{{ formatBalance(slotProps.row.totalSalesCents) }}</p>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue';
import Alert from '@/components/ui/Alert.vue';
import { format } from 'date-fns';

import ReportServiceV2 from '@/lib/report-service-v2';

export default {
  components: {
    GenericTable,
    Alert
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errorMessage: null,
      loading: true,
      columns: [
        { name: 'sellerName', label: 'Seller Name' },
        { name: 'refundsCreditCents', label: 'Refunds Credit', isCurrency: true },
        { name: 'refundsDebitCents', label: 'Refunds Debit', isCurrency: true },
        { name: 'refundsCashCents', label: 'Refunds Cash', isCurrency: true },
        { name: 'refunds', label: 'Refunds', isCurrency: true },
        { name: 'salesCreditCents', label: 'Sales Credit', isCurrency: true },
        { name: 'salesDebitCents', label: 'Sales Debit', isCurrency: true },
        { name: 'salesCashCents', label: 'Sales Cash', isCurrency: true },
        { name: 'total', label: 'Total', isCurrency: true }
      ],
      data: [],
      title: 'Sales by Seller',
      format: format,
      modalConfig: {
        showCloseModalButton: false
      }
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatBalance(balance) {
      const dollars = balance / 100;

      if (balance < 0) {
        return `(${this.formatCurrency(dollars * -1)})`;
      } else {
        return this.formatCurrency(dollars);
      }
    },

    async loadData() {
      try {
        const data = await ReportServiceV2.retrieveSalesBySellerReport({ eventId: this.eventId });

        this.data = data;
      } catch (error) {
        this.errorMessage = error.message;
      }
      this.loading = false;
    }
  }
};
</script>
