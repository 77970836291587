<template>
  <div class="flex">
    <RbButton iconVariant="faPlus" v-b-modal.create-event-member-modal variant="success"> Add </RbButton>

    <b-modal
      id="create-event-member-modal"
      title="Add In Support Of"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      size="md"
      body-class="position-static"
    >
      <b-overlay :show="loading" no-wrap rounded="sm">
        <template #overlay>
          <div class="text-center">
            <b-spinner class="ml-auto mr-auto" />
            <p>{{ loadingMessage }}</p>
          </div>
        </template>
      </b-overlay>
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

        <b-form-group label="Name" label-for="input-name" :invalid-feedback="veeErrors.first('input-name')">
          <b-form-input
            name="input-name"
            v-model="name"
            v-validate="{ required: true, min: 3, max: 255 }"
            :state="validateState('input-name')"
            aria-describedby="input-name-feedback"
            data-vv-as="name"
            trim
          />
        </b-form-group>

        <b-form-group label="Email" label-for="input-email" :invalid-feedback="veeErrors.first('input-email')">
          <b-form-input
            name="input-email"
            v-model="email"
            v-validate="{ email: true }"
            :state="validateState('input-email')"
            data-vv-as="email"
            trim
          />
        </b-form-group>

        <b-form-group
          label="Send Link?"
          label-for="input-send-link"
          :invalid-feedback="veeErrors.first('input-send-link')"
        >
          <b-form-select
            name="input-send-link"
            v-model="sendLink"
            :options="sendLinkOptions"
            v-validate="{ required: true }"
            :state="validateState('input-send-link')"
            data-vv-as="send link"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import EventServiceV2 from '@/lib/event-service-v2';
import MessageCenter from '@/lib/message-center';

export default {
  props: ['event'],
  data() {
    return {
      modalShow: false,
      name: null,
      email: null,
      loading: false,
      errorMessage: null,
      loadingMessage: null,
      sendLink: null,
      sendLinkOptions: [
        { text: '', value: null },
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ]
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    async resetForm() {
      this.name = null;
      this.email = null;

      this.errorMessage = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      const formValid = await this.$validator.validateAll();

      if (!formValid) {
        return;
      }

      this.loading = true;
      this.loadingMessage = 'Creating';

      const eventMember = {
        eventId: this.event.id,
        name: this.name,
        email: this.email
      };

      let eventMemberId;

      try {
        eventMemberId = await EventServiceV2.createEventMember(eventMember);
        this.$emit('created', eventMember);
      } catch (error) {
        if (error.response) {
          this.errorMessage = `Unable to create - ${error.response.data.errors[0].message}`;
        } else {
          this.errorMessage = 'An unexpected error occurred while creating, please try again later.';
        }
        this.loading = false;
        return;
      }

      if (this.sendLink && this.email && eventMemberId) {
        try {
          this.loadingMessage = 'Sending Link';
          await MessageCenter.sendEventMemberLink(this.event.id, eventMemberId);
        } catch (error) {
          if (error.response) {
            this.errorMessage = `Unable to send link - ${error.response.data.errors[0].message}`;
          } else {
            this.errorMessage = 'An unexpected error occurred while sending link, please try again later.';
          }
          this.loading = false;
          return;
        }
      }

      this.loading = false;
      this.modalShow = false;
    }
  }
};
</script>

<style scoped></style>
