<template>
  <!--Pick Winner-->
  <GenericModal size="sm" @modal-closed="modalClosed" :errorMessage="errorMessage">
    <template #openButton="{ openModal }">
      <div>
        <RbButton variant="success" @click.native="openModal">
          {{ getPrizeState(prizeId, 'pending') ? 'Confirm Winner' : 'Pick Winner' }}
        </RbButton>
      </div>
    </template>
    <template #header>
      <div class="text-lg">
        {{ getPrizeState(prizeId, 'pending') ? 'Confirm Winner' : 'Pick Winner' }}
      </div>
    </template>

    <div class="flex flex-column items-center" v-if="internalLoading">
      <p class="mb-4 mx-0">Initializing winner picking process...</p>
      <RbLoadingSpinner variant="default" size="lg" class="m-auto" />
    </div>

    <template v-if="!getPrizeState(prizeId, 'pending')">
      <div v-if="!internalLoading">
        <p class="mb-4 text-left" v-if="prizeQuantity > 1">
          You are about to pick the winners for this prize using the Random Number Generator.
          <span class="font-bold">This may take some time </span>and will happen in the background.
        </p>
        <Alert variant="yellow" icon="question">
          <div v-if="prizeQuantity > 1">
            <p class="font-bold">
              Once a winner is selected you will not be able to modify this Raffle. By clicking "Pick Winners" you have
              confirmed that all sales for the Raffle have been reconciled.
            </p>
          </div>

          <div v-else>
            <p class="mb-2">You are about to pick the winner for this prize.</p>
            <p class="mb-2">
              When you click "Pick Winner", a winner will be selected. However, you will still need to Confirm or Cancel
              the selected winner.
            </p>
          </div>
        </Alert>
        <ErrorDisplay v-if="pickWinnerErrorMessage" :error="pickWinnerErrorMessage" />
        <Alert v-if="voidedWinner" variant="red" icon="exclamation">
          The winning ticket selected was voided as part of
          <router-link
            :to="{
              name: 'Orders',
              query: { search: voidedWinner.order.id, eventId: voidedWinner.event.id }
            }"
            class="text-gray-800 button-link underline"
          >
            Order #{{ voidedWinner.order.id.substring(0, 6).toUpperCase() }} </router-link
          >. Please try again.
        </Alert>
      </div>
    </template>

    <template v-else-if="getPrizeState(prizeId, 'pending') && !internalLoading && prizeQuantity === 1">
      <Alert v-if="errorMessage" icon="exclamation" variant="red">{{ errorMessage }}</Alert>
      <div class="mb-4 text-left">Please review the selected winner. You will be asked to confirm the selection.</div>
      <div class="mb-4 pb-4 border-b-2 border-gray-300 text-left">
        <div class="leading-4 text-lg">Prize</div>
        <h1 class="text-2xl font-bold pb-0 pick-winner__prize-title">{{ prize.name }}</h1>
        <div class="pick-winner__prize-description">{{ prize.prizeValue }}</div>
      </div>

      <div class="mb-4 text-left">
        <div class="text-lg">Selected Winner</div>
        <h2 class="text-2xl font-bold font-header pb-2 pick-winner__winner-name">{{ selectedWinner.name }}</h2>
        <div class="text-lg pick-winner__info">
          <span>
            Ticket #: <strong>{{ selectedWinner.ticket }}</strong>
          </span>
          <br />
          <span>
            Order #:
            <a :href="'/orders?search=' + selectedWinner.order">
              <strong>{{ formatUuid(selectedWinner.order) }}</strong>
            </a>
          </span>
          <br />
          <span v-if="selectedWinner.secondaryName"> Additional Names: {{ selectedWinner.secondaryName }} </span>
        </div>

        <TextInput
          v-if="showCommentInput"
          label="Message"
          name="message"
          :required="true"
          v-model="comment"
          class="mt-5 font-bold"
        />
      </div>
    </template>

    <template slot="footer" slot-scope="{ closeModal }">
      <div class="flex py-1">
        <!--PICK WINNER FLOW-->
        <RbButton
          v-if="!getPrizeState(prizeId, 'pending')"
          variant="secondary"
          :disabled="internalLoading"
          @click.native="closePickWinnerModal(closeModal)"
        >
          Cancel
        </RbButton>
        <RbButton
          v-if="!getPrizeState(prizeId, 'pending')"
          variant="success"
          :disabled="internalLoading"
          class="ml-2"
          @click.native="pickWinner(closeModal)"
        >
          {{ label }}
        </RbButton>
        <RbButton v-if="showOk" variant="success" @click.native="handleOk(closeModal)"> Ok </RbButton>
        <!--CONFIRM WINNER FLOW-->
        <div v-else-if="getPrizeState(prizeId, 'pending')" class="flex flex-row">
          <RbButton
            variant="danger"
            :disabled="internalLoading"
            class="font-medium"
            @click.native="validateWinner(closeModal, 'cancelled')"
          >
            Reject
          </RbButton>
          <RbButton
            variant="success"
            :disabled="internalLoading"
            class="ml-1 font-medium"
            @click.native="validateWinner(closeModal, 'confirmed')"
          >
            Confirm
          </RbButton>
        </div>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import Alert from '@/components/ui/Alert';
import TextInput from '@/components/inputs/TextInput';
import WinnerService from '@/lib/winner-service-v2';
import GenericModal from '@/components/modals/GenericModal';
import { mapGetters } from 'vuex';
import ErrorDisplay from '@/components/ErrorDisplay';

export default {
  components: {
    Alert,
    GenericModal,
    TextInput,
    ErrorDisplay
  },
  props: {
    prizeId: {
      type: String,
      required: true
    },
    eventId: {
      type: String,
      required: true
    },
    prizeQuantity: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      internalLoading: false,
      prize: {},
      selectedWinner: {},
      comment: '',
      errorMessage: null,
      label: '',
      pickWinnerErrorMessage: null,
      voidedWinner: null,
      pickedWinner: null,
      showOk: false,
      showCommentInput: false
    };
  },
  watch: {
    label: function () {
      this.label = this.getPrizeState(this.prizeId, 'pending') ? 'Confirm Winner' : 'Pick Winner';
    }
  },
  computed: {
    ...mapGetters(['getPrizeWinners', 'getPrizeState'])
  },

  mounted() {
    const pendingState = this.getPrizeState(this.prizeId, 'pending');
    this.prize = this.$store.getters.getPrize(this.prizeId);
    this.label = pendingState ? 'Confirm Winner' : 'Pick Winner';
    if (pendingState) this.showCommentInput = true;
    if (pendingState) this.selectedWinner = this.formatSelectedWinner();
  },

  methods: {
    closePickWinnerModal(closeModal) {
      closeModal();
    },
    async reloadPrizes() {
      this.internalLoading = true;
      await this.$store.dispatch('listPrizes', this.eventId);
      this.internalLoading = false;
    },
    async reloadWinners() {
      this.internalLoading = true;

      await this.$store.dispatch('listPrizeWinners', {
        eventId: this.eventId
      });

      this.internalLoading = false;
    },
    handleOk(closeModal) {
      this.selectedWinner = this.formatSelectedWinner();
      closeModal();
      this.showOk = false;
      this.showCommentInput = true;
    },
    async pickWinner(closeModal) {
      this.voidedWinner = null;

      if (!this.pending) {
        try {
          this.internalLoading = true;

          const result = await WinnerService.createMulitWinner(
            this.$store.state.prizes.eventId,
            this.prizeId,
            this.prizeQuantity,
            this.prizeQuantity > 1 ? 'confirmed' : 'pending'
          );

          if (this.prizeQuantity === 1) {
            const winnerResponse = await WinnerService.retrieveWinner(result.id);
            this.pickedWinner = winnerResponse.data;

            if (this.pickedWinner.status === 'cancelled') {
              this.internalLoading = false;
              this.voidedWinner = this.pickedWinner;
              return;
            }
          } else {
            await this.reloadPrizes();
            closeModal();
            return;
          }
        } catch (error) {
          this.internalLoading = false;
          this.pickWinnerErrorMessage = error;
        }
      }

      if (!this.pickWinnerErrorMessage) {
        await this.updatePrizeWithWinner(closeModal);
        this.showOk = true;
      }

      if (this.prizeQuantity > 1) this.$store.dispatch('updatePickingBatchPrizeState', this.prizeId);
      this.internalLoading = false;
    },
    async updatePrizeWithWinner(closeModal) {
      try {
        await this.reloadWinners();

        this.prizeQuantity === 1 ? (this.selectedWinner = this.formatSelectedWinner()) : closeModal();
      } catch (error) {
        this.errorMessage = error;
      }
    },
    modalClosed() {
      this.internalLoading = false;
    },
    formatSelectedWinner() {
      const prizeWinners = this.getPrizeWinners(this.prizeId);

      const selectedWinner = prizeWinners.find((winner) => {
        return winner.status === 'pending';
      });

      return {
        id: selectedWinner.id,
        name: selectedWinner.order.name,
        ticket: selectedWinner.ticket.fullTicketNumber,
        order: selectedWinner.order.id,
        secondaryName: selectedWinner.order.secondaryName
      };
    },
    async validateWinner(closeModal, status) {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      this.internalLoading = true;

      try {
        await WinnerService.updateWinner(this.selectedWinner.id, {
          status: status,
          comment: this.comment
        });

        await this.reloadWinners();
        this.internalLoading = false;
      } catch (error) {
        this.errorMessage = error;
      } finally {
        this.comment = '';
        closeModal();
        this.showCommentInput = false;
      }
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style lang="scss">
.pick-winner-modal {
  color: #2c3e50;

  a {
    color: #2c3e50;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
