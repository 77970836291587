<template>
  <div class="flex">
    <RbButton
      iconVariant="faArrowUpFromLine"
      variant="secondary-outline"
      v-b-modal.import-event-members-modal-v2
      v-if="canImportEventMembers"
    >
    </RbButton>
    <b-modal
      id="import-event-members-modal-v2"
      centered
      title="Import 'In Support Of'"
      @ok="handleOk(event, $event)"
      @show="resetForm"
      @hidden="resetForm"
      body-class="position-static"
      no-close-on-backdrop
    >
      <input type="file" id="file" ref="file" v-on:change="handleFileUpload" />
      <b-alert v-if="errorMessage" show variant="danger" class="mt-2">{{ errorMessage }}</b-alert>
    </b-modal>
  </div>
</template>
<script>
import EventServiceV2 from '../lib/event-service-v2';
import { featureEnabled, LaunchDarklyKeys } from '../lib/launch-darkly';

export default {
  props: ['event'],
  data() {
    return {
      success: {
        text: '',
        model: false
      },
      errorMessage: null,
      file: null,
      canImportEventMembers: false
    };
  },
  async mounted() {
    this.canImportEventMembers = await featureEnabled(LaunchDarklyKeys.ImportEventMembers);
  },
  methods: {
    resetForm() {
      this.success = {
        text: '',
        model: false
      };
      this.errorMessage = null;
      this.file = null;
    },
    handleSuccess(text) {
      this.success.text = text;
      this.success.model = true;
      this.errorMessage = null;
      this.$root.$emit('bv::hide::modal', 'import-event-members-modal-v2');
      location.reload();
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    async handleOk(event, bvModalEvt) {
      this.errorMessage = null;

      bvModalEvt.preventDefault();

      if (this.file) {
        try {
          const formData = new FormData();
          formData.append('file', this.file);
          formData.append('eventId', event.id);
          await EventServiceV2.uploadEventMembers(event.id, formData);
          this.handleSuccess('Upload Successful');
        } catch (error) {
          if (error.response?.data.errors) {
            this.errorMessage = error.response.data.errors[0].message;
          } else {
            this.errorMessage = 'An unexpected error occurred, please try again later.';
          }
        }
      }
    }
  }
};
</script>
<style scoped></style>
