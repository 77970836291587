<template>
  <div>
    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">Configuration</h1>
      <ul class="mb-4">
        <li>
          <div class="flex">
            <RbToggle
              v-model="rulesEnabled"
              name="show-rules-button"
              :disabled="isDisabled('rulesEnabled')"
              label="Display Rules"
              variant="success"
              @change="rulesEnabled = $event"
            />
            <sup v-b-tooltip.hover.top="'Display the user entered rules on the live raffle page.'" class="mt-2 ml-2">
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>

        <li>
          <div class="flex">
            <RbToggle
              v-model="postalCodeCheck"
              :state="validateState('postal-code-check-button')"
              name="postal-code-check-button"
              :disabled="isDisabled('postalCodeCheck')"
              label="Postal Code Check"
              variant="success"
              @change="postalCodeCheck = $event"
            />
            <sup
              v-b-tooltip.hover.top="
                'If enabled then the system will verify the Ticket Buyer\'s billing address with the postal code they enter. This is used to reduce fraud and to ensure that the Ticker Buyer is in the province of the Raffle.'
              "
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>

        <li>
          <div class="flex">
            <RbToggle
              v-model="geoLocation"
              :state="validateState('hide-geo-location')"
              name="hide-geo-location"
              :disabled="isDisabled('settings.geoLocation')"
              label="Enable Geo Location Services"
              variant="success"
              @change="geoLocation = $event"
            />
            <sup
              v-b-tooltip.hover.top="
                'If enabled then the system will ask the Ticket Buyer to enable Location Services. This is used to ensure that the Ticket Buyer is in the province of the Raffle.'
              "
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>

        <li>
          <div class="flex">
            <RbToggle
              v-model="convertedMaxOrdersPerCustomer"
              :state="validateState('max-orders')"
              name="max-orders"
              :disabled="isDisabled('settings.maxOrdersPerCustomer')"
              label="Max One Order per Ticket Buyer"
              variant="success"
              @change="updateMaxOrdersPerCustomer"
            />
            <sup
              v-b-tooltip.hover.top="
                'When enabled, Ticket Buyers will be restricted to a single order on this raffle. This is determined by the Ticket Buyer\'s email, or Customer ID if Goldrush.'
              "
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>

        <li>
          <div class="flex">
            <RbToggle
              v-model="ticketNumbersShow"
              :state="validateState('hide-ticket-numbers')"
              name="hide-ticket-numbers"
              :disabled="isDisabled('hideTicketNumbers')"
              label="Hide Ticket Numbers in Email"
              variant="success"
              @change="ticketNumbersShow = $event"
            />
            <sup
              v-b-tooltip.hover.top="
                'If enabled then the emails sent to the Ticket Buyers will not contain the ticket numbers.'
              "
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>

        <li>
          <div class="flex">
            <RbToggle
              v-model="hideSocialLinks"
              :state="validateState('hide-social-links')"
              name="hide-social-links"
              :disabled="isDisabled('hideSocialLinks')"
              label="Hide Social Links on Raffle Page"
              variant="success"
              @change="hideSocialLinks = $event"
            />
            <sup
              v-b-tooltip.hover.top="
                'If enabled then Raffle page will not display the Share on Social links (eg. Twitter / Facebook).'
              "
              class="mt-2 ml-2"
            >
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>

        <li v-if="canViewDonations">
          <div class="flex">
            <RbToggle
              v-model="donationEnabled"
              :state="validateState('donation-enabled')"
              name="donation-enabled"
              :disabled="isDisabled('donation-enabled')"
              label="Enable Donations"
              variant="success"
              @change="donationEnabled = $event"
            />
            <sup v-b-tooltip.hover.top="'If enabled, donations will be accepted during purchases.'" class="mt-2 ml-2">
              <span class="fas fa-info-circle"></span>
            </sup>
          </div>
        </li>
      </ul>
      <b-form-group
        label-for="input-barrel-draw"
        :invalid-feedback="veeErrors.first('input-barrel-draw')"
        description=""
      >
        <template slot="label">
          Type of Draw
          <sup
            v-b-tooltip.hover.top="
              'If using Random Number generator, ensure you have approval from your provincial regulator.'
            "
          >
            <span class="fas fa-info-circle"></span>
          </sup>
        </template>
        <b-form-select
          name="input-draw-type"
          :state="validateState('input-draw-type')"
          v-model="drawType"
          aria-describedby="input-draw-type-feedback"
          :options="drawTypeOptions"
          v-validate="{ required: true }"
          data-vv-as="draw type"
          style="max-width: 15rem; height: 2.5rem; border-radius: 5px"
          :disabled="isDisabled('drawType')"
        />
      </b-form-group>

      <b-form-group label="Link Parent Raffle" label-for="input-event-parent" style="max-width: 40rem">
        <EventSelectV3 v-model="parentEvent" :organizationId="raffle.organizationId" :currentEvent="raffle" />
      </b-form-group>
    </section>

    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">Tracking Codes</h1>
      <div class="d-md-flex align-items-start">
        <div class="sm-wrap">
          <b-form-group
            label="Facebook Pixel Code"
            label-for="input-pixel-code"
            :invalid-feedback="veeErrors.first('input-pixel-code')"
          >
            <b-form-input
              class="input-sm-width"
              name="input-pixel-code"
              v-model="pixelCode"
              :state="validateState('input-pixel-code')"
              v-validate="{}"
              aria-describedby="input-pixel-code-feedback"
              data-vv-as="pixel code"
              :disabled="isDisabled('pixelCode')"
            />
          </b-form-group>
        </div>
        <div class="sm-wrap">
          <b-form-group
            label="Google Tag Manager"
            label-for="input-gtm-code"
            :invalid-feedback="veeErrors.first('input-gtm-code')"
          >
            <b-form-input
              class="input-sm-width"
              name="input-gtm-code"
              v-model="gtmCode"
              :state="validateState('input-gtm-code')"
              v-validate="{}"
              aria-describedby="input-gtm-code-feedback"
              data-vv-as="gtm code"
              :disabled="isDisabled('gtmCode')"
            />
          </b-form-group>
        </div>
      </div>
    </section>
    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">Jackpot Messaging</h1>

      <b-form-checkbox
        v-model="customJackpotEnabled"
        :state="validateState('enable-custom-jackpot-message')"
        name="enable-custom-jackpot-message"
        :disabled="isDisabled('customJackpotMessage')"
        switch
      >
        <span class="pr-1">Enable custom jackpot message</span>
        <sup
          v-b-tooltip.hover.top="
            'If enabled, the Raffle page will display the information below in the sidebar JackpotCard.'
          "
        >
          <em class="fas fa-info-circle"></em>
        </sup>
      </b-form-checkbox>

      <b-form-group
        label-for="input-statement"
        description="This has a larger display than the sub-title. Max character count of 80."
        :invalid-feedback="veeErrors.first('input-statement')"
      >
        <template slot="label"> Main Statement <span v-if="requiredMessage" class="text-danger">*</span> </template>
        <b-form-input
          class="input-lg-width"
          name="input-statement"
          v-model="jackpotStatement"
          :state="validateState('input-statement')"
          v-validate="{ required: requiredMessage, max: 80 }"
          data-vv-as="main statement"
          placeholder="ie. $90,000"
          :disabled="!requiredMessage"
        />
      </b-form-group>

      <b-form-group
        label="Sub-title"
        label-for="input-sub-title"
        description="Max character count of 80."
        :invalid-feedback="veeErrors.first('input-sub-title')"
      >
        <b-form-input
          class="input-lg-width"
          name="input-sub-title"
          v-model="jackpotSubTitle"
          :state="validateState('input-sub-title')"
          v-validate="{ max: 80 }"
          data-vv-as="jackpot sub-title"
          placeholder="ie. winner takes half"
          :disabled="!requiredMessage"
        />
      </b-form-group>
    </section>

    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">White Label Settings</h1>
      <ul class="mb-4">
        <li class="pb-2">
          <b-form-checkbox
            v-model="whiteLabel.enabled"
            :state="validateState('enable-whiteLabel')"
            name="hide-pot-button"
            :disabled="isDisabled('settings.whiteLabel.enabled')"
            switch
          >
            <span class="pr-1">Enable White Label Options</span>
          </b-form-checkbox>
        </li>
        <li class="pb-1">
          <b-form-group
            label-for="input-button-color"
            description="Hover over example text to select checkout color. Color should contrast with the white text."
            :invalid-feedback="veeErrors.first('input-button-color')"
          >
            <template slot="label">Checkout Primary Color</template>
            <div class="flex">
              <div class="relative color-picker">
                <div class="color-picker__picker">
                  <Chrome :value="whiteLabel.buttonColor" @input="(c) => (whiteLabel.buttonColor = c.hex)" />
                </div>
                <div class="inline-block border rounded-lg p-2 cursor-pointer color-picker__button">
                  <span :style="{ backgroundColor: whiteLabel.buttonColor }">Example Text</span>
                </div>
              </div>
              <b-form-input
                class="input-xs-width"
                name="input-button-color"
                v-model="whiteLabel.buttonColor"
                :state="validateState('input-button-color')"
                placeholder="#5db966"
                readonly
              />
            </div>
          </b-form-group>
        </li>
        <li class="pb-1" style="display: none">
          <b-form-group
            label-for="input-checkout-banner"
            description="A url of a valid image is required. Optimal image size is 300px x 100px."
            :invalid-feedback="veeErrors.first('input-checkout-banner')"
          >
            <template slot="label">Checkout Logo Url</template>
            <b-form-input
              class="input-lg-width"
              name="input-checkout-banner"
              v-model="whiteLabel.checkoutBannerUrl"
              :state="validateState('input-checkout-banner')"
              v-validate="{ url: { require_protocol: true } }"
              data-vv-as="checkout logo url"
              placeholder="https://..."
            />
          </b-form-group>
        </li>
      </ul>
    </section>
    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">Ticket Buyer Statement Descriptor</h1>
      <b-form-group
        label-for="statementDescriptor"
        description="This is the text that will appear on the Ticket Buyer's credit card statement."
        :invalid-feedback="veeErrors.first('statementDescriptor')"
      >
        <b-form-input
          class="input-lg-width"
          name="statementDescriptor"
          v-model="statementDescriptor"
          :state="validateState('statementDescriptor')"
          v-validate="{ min: 5, max: 22, regex: regex }"
          data-vv-as="statementDescriptor"
        />
      </b-form-group>
    </section>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { Chrome } from 'vue-color';
import EventSelectV3 from '@/components/EventSelectV3.vue';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  components: {
    EventSelectV3,
    Chrome
  },
  computed: {
    ...mapFields([
      'rbAdminForm.rulesEnabled',
      'rbAdminForm.postalCodeCheck',
      'rbAdminForm.drawType',
      'rbAdminForm.ticketNumbersShow',
      'rbAdminForm.drawTypeOptions',
      'settings.geoLocation',
      'settings.maxOrdersPerCustomer',
      'settings.hideSocialLinks',
      'settings.donationEnabled',
      'settings.customJackpotEnabled',
      'settings.jackpotStatement',
      'settings.jackpotSubTitle',
      'settings.pixelCode',
      'settings.gtmCode',
      'settings.whiteLabel',
      'settings.whiteLabel.buttonColor',
      'settings.whiteLabel.checkoutBannerUrl',
      'setting.whiteLabel.enabled',
      'rbAdminForm.statementDescriptor',
      'rbAdminForm.parentEvent'
    ]),
    settings() {
      return this.$store.getters.getSettings;
    },
    raffle() {
      return this.$store.getters.getRaffle;
    },
    status() {
      return this.$store.getters.getStatus;
    },
    requiredMessage() {
      return this.settings.customJackpotEnabled;
    },
    convertedMaxOrdersPerCustomer() {
      return this.convertToBoolean(this.maxOrdersPerCustomer);
    }
  },
  data() {
    return {
      regex: /^(?=.*[a-zA-Z])[^<>\\'"*]+$/,
      showRBAdminSettings: false,
      editRaffleRestricted: false,
      canViewDonations: false
    };
  },
  async mounted() {
    this.showRBAdminSettings = await featureEnabled(LaunchDarklyKeys.EditRaffleRBAdminSettings);
    this.editRaffleRestricted = await featureEnabled(LaunchDarklyKeys.EditRaffleRestricted);
    this.canViewDonations = await featureEnabled(LaunchDarklyKeys.ViewDonations);
  },
  watch: {
    customJackpotEnabled: function () {
      if (!this.requiredMessage) {
        this.$store.dispatch('setSettings', {
          ...this.settings,
          jackpotStatement: '',
          jackpotSubTitle: ''
        });
      }
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    convertToBoolean(value) {
      return value === 1;
    },
    updateMaxOrdersPerCustomer(newValue) {
      this.maxOrdersPerCustomer = newValue ? 1 : 0;
    },
    isDisabled(element) {
      if (this.editRaffleRestricted) {
        return false;
      }

      const disabledFields = [
        'drawType',
        'startDate',
        'startTime',
        'startingTicketNumber',
        'startingPot',
        'minimumJackpotCents',
        'fractionalJackpotPercent',
        'rules',
        'licenseNumber',
        'name',
        'province',
        'category',
        'drawLocation',
        'customJackpotMessage',
        'licenseUrl'
      ];

      if (this.status === 'pending') {
        return false;
      }

      if (['active', 'ended'].includes(this.status)) {
        return disabledFields.includes(element);
      }

      if (this.status === 'closed') {
        return element !== 'shortlink';
      }

      return true;
    }
  }
};
</script>
