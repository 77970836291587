<template>
  <div class="sm:mx-2 md:max-w-7x">
    <div class="flex justify-center" v-if="loading && !error">
      <RbLoadingSpinner />
    </div>
    <div v-else>
      <div class="flex mb-3 justify-between xs:items-baseline">
        <h1 class="text-2xl font-medium mb-0">In Support Sales</h1>

        <DownloadReportButton @onClick="downloadReport" :downloadingReport="downloadingReport" />
      </div>
      <div v-if="!loading && error">
        <Alert v-if="error" variant="red" icon="exclamation">{{ error }}</Alert>
      </div>
      <div class="max-w-full" v-else>
        <table class="min-w-full divide-y divide-gray-300" v-if="salesReport.eventId">
          <caption class="sr-only">
            In Support Sales Report
          </caption>
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-900">#</th>
              <th scope="col" class="px-3 py-3 text-left text-xs font-bold tracking-wide text-gray-900">Name</th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-36">
                Qty Refunds
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-48">
                Refunds
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-36">
                Qty Orders
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-48">
                Total Sales
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="item in salesReport.items" :key="item.eventMemberId">
              <td class="whitespace-nowrap py-4 text-left text-sm font-medium text-gray-900 sm:pl-6">
                {{ item.eventMemberNumber }}
              </td>
              <td class="whitespace-nowrap py-4 text-left text-sm font-medium text-gray-900 sm:pl-6">
                {{ item.eventMemberName }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-800">{{ item.numRefunds }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-800">
                {{ formatCurrency(item.refundsCents / 100) }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-800">{{ item.numOrders }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-800">
                {{ formatCurrency(item.salesCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert.vue';
import { downloadFormattedCSV } from '@/lib/download-file';
import DownloadReportButton from '@/components/ui/DownloadReportButton';
import ReportServiceV2 from '@/lib/report-service-v2';

export default {
  components: {
    Alert,
    DownloadReportButton
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      downloadingReport: false
    };
  },
  async mounted() {
    await this.getReport();
  },

  computed: {
    salesReport() {
      return this.$store.state.inSupportOfSalesReport.report;
    },
    loading() {
      return this.$store.state.inSupportOfSalesReport.loading;
    },
    error() {
      return this.$store.state.inSupportOfSalesReport.error;
    }
  },
  methods: {
    async getReport() {
      await this.$store.dispatch('getInSupportOfSales', this.eventId);
    },
    async downloadReport() {
      this.downloadingReport = true;

      try {
        const data = await ReportServiceV2.inSupportOfSales(this.eventId, {}, 'text/csv');

        downloadFormattedCSV(`event_sales_${Date.now()}.csv`, data);
      } catch (error) {
        const formattedError = this.parseError(error).message;
        this.$store.commit('SET_ERROR', formattedError);
      }

      this.downloadingReport = false;
    }
  }
};
</script>
