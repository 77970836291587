<template>
  <GenericModal @modal-closed="modalClosed">
    <template #openButton="{ openModal }">
      <div>
        <RbButton variant="success-outline" @click.native="openModal"> Activate All </RbButton>
      </div>
    </template>
    <template #header>
      <div class="text-lg rb-modal__title">Activate All</div>
    </template>
    <template>
      <p class="py-4">Are you sure you want to activate all event members for this raffle?</p>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <div class="flex py-1">
        <RbButton variant="secondary" @click.native="closeModalHandler(closeModal)" class="mr-2"> Cancel </RbButton>
        <RbButton variant="success" @click.native="activateAll(closeModal)"> Yes </RbButton>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import EventServiceV2 from '@/lib/event-service-v2';

export default {
  components: {
    GenericModal
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalConfig: {
        showCloseModalButton: false
      },
      errorMessage: null,
      loading: false
    };
  },
  methods: {
    async activateAll(closeModal) {
      try {
        await EventServiceV2.updateMultipleEventMembers({ eventId: this.event.id, active: true });
        closeModal();
        this.$emit('activateAll');
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    closeModalHandler(closeModal) {
      closeModal();
    },
    modalClosed() {
      this.errorMessage = null;
    }
  }
};
</script>
