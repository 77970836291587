<template>
  <div v-if="winner" class="grand-prize-winner">
    <div
      class="
        xl:flex
        flex-nowrap
        justify-between
        items-center
        p-5
        rounded-lg
        bg-green-200
        text-green-800
        border border-green-600
        grand-prize-winner__item
      "
    >
      <div class="xl:flex xxl:w-8/12 justify-between grand-prize-winner__name-label">
        <div class="flex mr-4">
          <FontAwesomeIcon class="fa-3x mr-2 text-green-800" :icon="['far', 'medal']" />
          <div>
            <div class="font-header font-medium text-3xl grand-prize-winner__winner">
              Winner: {{ winner.order.name }}
            </div>
            <div v-if="winner.order.secondaryName" class="text-base grand-prize-winner__secondary-name">
              <p>Additional Names:</p>
              <strong>{{ winner.order.secondaryName }}</strong>
            </div>
          </div>
        </div>
        <div v-if="event.settings.grandPrizeTitle" class="grand-prize-winner__label">
          <strong class="block">{{ event.settings.grandPrizeTitle }}</strong>
          <p>{{ event.settings.grandPrizeDescription }}</p>
        </div>
        <div v-else-if="event.category !== 'Raffle'" class="grand-prize-winner__label">
          <strong class="block">Grand Prize</strong>
          <p>One winner will receive 50% of the total jackpot!</p>
        </div>
      </div>

      <div class="grand-prize-winner__ticket-number">
        <div v-if="winner.status === 'confirmed'" class="font-header font-medium text-2xl ticket-number">
          Ticket #: {{ winner.ticket.fullTicketNumber }}
        </div>
        <div v-else class="bg-yellow-600 text-green-900 px-4 py-2 rounded-full prize-list-item__winner-pending">
          Winner Pending
        </div>
      </div>

      <div class="text-right grand-prize-winner__drawer-toggle">
        <button class="px-4 py-1 text-right prize-list-item--drawer-toggle" @click="toggleDrawer">
          <FontAwesomeIcon v-if="drawerOpen" class="fa-3x" :icon="['far', 'chevron-up']" />
          <FontAwesomeIcon v-else class="fa-3x" :icon="['far', 'chevron-down']" />
        </button>
      </div>
    </div>
    <CollapsibleDrawer :controls="false" :externalToggle="drawerOpen" :initial="false">
      <GrandPrizeDrawerContent
        :winner="winner"
        :cancelled="cancelled"
        :time-zone="event.timeZone"
        :drawType="drawType"
        @complete="complete"
      />
    </CollapsibleDrawer>
  </div>
</template>

<script>
import CollapsibleDrawer from '@/components/ui/CollapsibleDrawer';
import GrandPrizeDrawerContent from '@/components/prizes/GrandPrizeDrawerContent';

export default {
  components: {
    CollapsibleDrawer,
    GrandPrizeDrawerContent
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    winners: {
      type: Array,
      required: true
    },
    cancelled: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      drawerOpen: false
    };
  },
  mounted() {
    if (this.winner) this.$store.dispatch('getWinningOrder', this.winner.order.id);
  },
  computed: {
    winner() {
      if (this.winners.length) {
        return this.winners[0];
      }
      return null;
    },
    drawType() {
      if (this.event) {
        return this.event.drawType;
      }

      return null;
    }
  },
  methods: {
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    complete(winner) {
      this.$emit('complete', winner);
    }
  }
};
</script>
