<template>
  <ValidationObserver ref="observer" slim v-slot="{ errors }">
    <GenericModal
      :title="getTitle"
      :config="modalConfig"
      :errorMessage="(errors.amount ? errors.amount[0] : null) || errorMessage"
      :successMessage="successMessage"
      @modal-closed="modalClosed"
      @modal-opened="onModalOpen"
    >
      <template #openButton="{ openModal }">
        <div class="whitespace-nowrap text-gray-800 underline">
          <a @click="openModal" class="text-gray-800 cursor-pointer">{{ formatBalance(row.balanceCents) }}</a>
        </div>
      </template>
      <template>
        <div class="overflow-y-auto max-h-96">
          <GenericTable :columns="columns" :data="data" :loading="loading" :actions="!interactionsDisabled">
            <template v-slot:ticketSellerName="slotProps">
              <p class="text-left text-sm">
                {{ formatName(slotProps.row.ticketSellerName) }}
              </p>
            </template>

            <template v-slot:amountCents="slotProps">
              <p class="text-right text-sm" v-if="editingRow === slotProps.index">
                <b-form @submit.prevent>
                  <ValidationProvider
                    vid="amount"
                    :rules="{ min_value: -999999, max_value: 999999, integer: true, required: true }"
                    name="amount"
                  >
                    <b-form-group label-for="amount" class="text-base m-0" slot-scope="{ valid, errors }">
                      <b-form-input
                        name="amount"
                        v-model="editAmountDollars"
                        class="text-right text-sm"
                        :state="errors[0] ? false : valid ? true : null"
                        type="text"
                        size="sm"
                        placeholder="0"
                        aria-describedby="amount-feedback"
                        data-vv-as="Cash Drop Amount"
                        trim
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-form>
              </p>
              <p class="text-right text-sm" v-else>
                {{ formatBalance(slotProps.row.amountCents, slotProps.row.float) }}
              </p>
            </template>
            <template v-slot:createdAt="slotProps">
              <div class="text-right text-sm whitespace-nowrap">
                {{ format(new Date(slotProps.row.createdAt), 'h:mm a MMM dd, yy') }}
              </div>
            </template>
            <template v-slot:actions="slotProps">
              <div v-if="editingRow === slotProps.index" class="whitespace-nowrap">
                <LoadingButton class="px-3 mr-1" @onClick="cancelEdit()" size="small" v-if="!submitting">
                  <FontAwesomeIcon class="text-md" :icon="['far', 'x']" />
                </LoadingButton>
                <LoadingButton
                  class="px-3"
                  @onClick="submitUpdate(slotProps.index, slotProps.row)"
                  variant="green"
                  size="small"
                  :loading="submitting"
                >
                  <FontAwesomeIcon class="text-md" :icon="['far', 'check']" v-if="!submitting" />
                </LoadingButton>
              </div>
              <div v-else-if="deletingRow === slotProps.index">
                <LoadingButton class="mr-1 px-3" @onClick="cancelDelete()" size="small" v-if="!deleting">
                  <FontAwesomeIcon class="text-md" :icon="['far', 'x']" />
                </LoadingButton>
                <LoadingButton
                  class="px-3"
                  @onClick="submitDelete(slotProps.index, slotProps.row)"
                  variant="red"
                  size="small"
                  :loading="deleting"
                >
                  <FontAwesomeIcon class="text-md" :icon="['far', 'trash-can']" v-if="!deleting" />
                </LoadingButton>
              </div>
              <div v-else>
                <button class="px-2" @click="editRow(slotProps.index, slotProps.row)">
                  <FontAwesomeIcon class="text-md" :icon="['far', 'pen-to-square']" />
                </button>
                <button class="px-2" @click="deleteRow(slotProps.index)">
                  <FontAwesomeIcon class="text-md" :icon="['far', 'trash-can']" />
                </button>
              </div>
            </template>
          </GenericTable>
        </div>
      </template>
    </GenericModal>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import GenericModal from '@/components/modals/GenericModal.vue';
import GenericTable from '@/components/GenericTable.vue';
import LoadingButton from '@/components/ui/LoadingButton.vue';
import DeviceServiceV2 from '@/lib/device-service-v2';
import { format } from 'date-fns';

export default {
  components: {
    GenericModal,
    GenericTable,
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    row: {
      type: Object,
      required: true
    },
    interactionsDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        { name: 'ticketSellerName', label: 'Seller Name', classes: ['text-left'] },
        { name: 'amountCents', label: 'Amount', isCurrency: true },
        { name: 'createdAt', label: 'Time', classes: ['text-right'] }
      ],
      data: [],
      title: 'Activity',
      format: format,
      modalConfig: {
        showCloseModalButton: false,
        cancelButtonLabel: 'Close'
      },
      editingRow: null,
      deletingRow: null,
      editAmountDollars: null,
      errorMessage: null,
      successMessage: null,
      submitting: false,
      deleting: false,
      loading: true
    };
  },
  computed: {
    getTitle() {
      return `${this.title} - ${this.row.ticketSellerName || this.row.deviceName}`;
    }
  },
  methods: {
    formatBalance(balance, isFloat) {
      if (balance < 0 || isFloat) {
        return `(${this.formatCurrency((balance * -1) / 100)})`;
      } else {
        return this.formatCurrency(balance / 100);
      }
    },

    formatName(name) {
      if (name !== null) {
        return name;
      } else {
        return 'None';
      }
    },

    async getCashDrops() {
      this.loading = true;
      const eventId = this.row.eventId;
      const deviceId = this.row.deviceId;
      const response = await DeviceServiceV2.listCashDrops(eventId, deviceId);
      this.loading = false;

      return response.data;
    },
    async onModalOpen() {
      this.data = (await this.getCashDrops()).data;
    },
    editRow(index, row) {
      this.successMessage = null;
      this.editingRow = index;
      this.editAmountDollars = row.amountCents / 100;
    },
    cancelEdit() {
      this.editingRow = null;
      this.errorMessage = null;
      this.successMessage = null;
    },
    cancelDelete() {
      this.deletingRow = null;
    },
    deleteRow(index) {
      this.deletingRow = index;
    },
    async submitUpdate(index, row) {
      this.errorMessage = null;

      try {
        if (await this.$refs.observer.validate()) {
          this.submitting = true;
          await DeviceServiceV2.updateCashDrop(row.id, { amountCents: this.editAmountDollars * 100 });

          this.editingRow = null;
          this.editAmountDollars = null;
          this.data = (await this.getCashDrops()).data;
          this.successMessage = 'Cash drop updated successfully';
          this.$emit('cash-drop-updated');
        }
      } catch (error) {
        this.errorMessage = `Error Updating: ${this.parseError(error).message}`;
      }
      this.submitting = false;
    },
    async submitDelete(index, row) {
      try {
        this.deleting = true;
        await DeviceServiceV2.deleteCashDrop(row.id);

        this.deletingRow = null;
        this.data = (await this.getCashDrops()).data;
        this.successMessage = 'Cash drop deleted successfully';
        this.$emit('cash-drop-deleted');
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
      this.deleting = false;
    },
    modalClosed() {
      this.errorMessage = null;
      this.successMessage = null;
      this.$emit('modal-closed');
    }
  }
};
</script>
