<template>
  <div class="my-3">
    <h4 @click="visible = !visible" class="cursor-pointer">
      Example Calculator
      <span class="ml-3 text-gray-400">
        <FontAwesomeIcon :icon="['far', 'chevron-down']" v-if="!visible" />
        <FontAwesomeIcon :icon="['far', 'chevron-up']" v-if="visible" />
      </span>
    </h4>
    <b-collapse id="calculator-drawer" v-model="visible" class="mt-2">
      <b-card>
        <div class="flex flex-row justify-content-between">
          <div class="w-1/3">
            <div class="max-field-width">
              <b-form-group
                label="Total Raised"
                label-for="input-total-raised"
                :invalid-feedback="veeErrors.first('input-total-raised')"
                label-size="md"
                style="max-width: 15rem"
              >
                <b-input-group prepend="$" append=".00" class="mr-3">
                  <b-form-input
                    v-model="totalRaised"
                    name="input-total-raised"
                    v-validate="{ min_value: 0, integer: true }"
                    type="number"
                    min="0"
                    :state="validateState({ min_value: 0 })"
                    aria-describedby="input-total-raised-feedback"
                    data-vv-as="Total Raised"
                    trim
                  />
                </b-input-group>
              </b-form-group>
            </div>
            <div class="flex flex-row justify-content-between max-field-width">
              <div>
                <p>Starting</p>
                <p>{{ formatCurrency(starting, { precision: 0 }) }}</p>
              </div>
              <div>
                <p>Minimum</p>
                <p>{{ formatCurrency(minimum, { precision: 0 }) }}</p>
              </div>
            </div>
          </div>
          <div class="w-2/3 flex flex-col items-center">
            <h3>Jackpot Display</h3>
            <h3 class="text-green-600 mb-3">{{ formatCurrency(calculatedJackpot, { precision: 0 }) }}</h3>
            <div class="flex flex-row ml-12 align-items-center">
              <VueSlider
                v-model="percentage"
                :width="300"
                :height="18"
                contained
                tooltip="none"
                :min="1"
                :max="100"
                :dotOptions="{ focusStyle: { 'box-shadow': '0 0 0 5px rgb(23, 157, 23 / 20%)' } }"
                :rail-style="{ backgroundColor: '#ebebeb' }"
                :processStyle="{ backgroundColor: '#179d17' }"
                class="max-w-full"
              >
                <template v-slot:dot="{ pos }">
                  <div :class="`h-4 w-4 border border-gray-400 rounded-lg bg-white ${dotPosition(pos)}`"></div>
                </template>
              </VueSlider>
              <p class="mb-0 ml-3 w-10">{{ this.percentage }}%</p>
            </div>
          </div>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import { calculateJackpotCents } from '@rafflebox-technologies-inc/rafflebox-lib';

export default {
  components: {
    VueSlider
  },
  props: {
    starting: {
      type: [Number, String]
    },
    minimum: {
      type: [Number, String]
    },
    maximum: {
      type: [Number, String]
    }
  },
  data() {
    return {
      visible: false,
      totalRaised: 0,
      percentage: 100
    };
  },
  computed: {
    calculatedJackpot() {
      return this.calculateExampleJackpot();
    }
  },
  watch: {
    starting: function () {
      this.calculateExampleJackpot();
    },
    minimum: function () {
      this.calculateExampleJackpot();
    }
  },
  methods: {
    calculateExampleJackpot() {
      const startingCents = Number(this.starting) * 100;
      const minimumCents = Number(this.minimum) * 100;
      const maximumCents = Number(this.maximum) * 100;
      const jackpotCents = Number(this.totalRaised) * 100;
      const percent = this.percentage;

      return calculateJackpotCents(startingCents, jackpotCents, minimumCents, maximumCents, percent) / 100;
    },
    dotPosition(pos) {
      if (pos > 0) {
        return 'dot-position-end';
      } else {
        return 'dot-position-start';
      }
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.max-field-width {
  max-width: 14rem;
}

.form-group,
.rb-field-group {
  margin-right: 0 !important;
}

.dot-position-end {
  margin-left: -10px;
  margin-top: -1px;
}

.dot-position-start {
  margin-left: 10px;
  margin-top: -1px;
}
</style>
