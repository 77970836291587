<template>
  <div class="sm:mx-2 md:max-w-7xl">
    <div v-if="loading && !error" class="flex justify-center">
      <RbLoadingSpinner />
    </div>
    <Alert v-else-if="!loading && error" id="error-alert" class="mt-2" icon="exclamation" variant="red">{{
      error
    }}</Alert>
    <Alert
      v-else-if="!(salesReport.items && salesReport.items.length)"
      id="no-device-sales-recorded-alert"
      class="mt-2"
      icon="info"
      variant="yellow"
      >No device sales have been recorded yet. Please check back later.
    </Alert>
    <div v-else>
      <div>
        <div class="flex mb-3 justify-between xs:items-baseline">
          <h1 class="text-2xl font-medium mb-0">Device Sales</h1>
          <DownloadReportButton @onClick="downloadReport" :downloadingReport="downloadingReport" />
        </div>
      </div>
      <div class="max-w-full overflow-x-scroll">
        <table class="min-w-full divide-y divide-gray-300">
          <caption class="sr-only">
            Device Sales Report
          </caption>
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-xs tracking-wide text-gray-900 w-44">
                Device Name
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Refunds Credit
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Refunds Debit
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Refunds Cash
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Refunds
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Sales Credit
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Sales Debit
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Sales Cash
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Total Sales
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="device in salesReport.items" :key="device.deviceName">
              <td class="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {{ device.deviceName }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p :id="`credit-refunds-${device.postgresDeviceUuid}`" class="dotted-underline">
                  {{ formatCurrency(device.refundsCreditCents / 100) }}
                </p>
                <b-tooltip :target="`credit-refunds-${device.postgresDeviceUuid}`" triggers="hover" placement="right">
                  <DeviceSalesReportTooltip
                    :quantity="device.numRefundsCredit"
                    :amount="formatCurrency(device.refundsCreditCents / 100)"
                    amountTitle="Amount Refunded"
                  />
                </b-tooltip>
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p :id="`debit-refunds-${device.postgresDeviceUuid}`" class="dotted-underline">
                  {{ formatCurrency(device.refundsDebitCents / 100) }}
                </p>
                <b-tooltip :target="`debit-refunds-${device.postgresDeviceUuid}`" triggers="hover" placement="right">
                  <DeviceSalesReportTooltip
                    :quantity="device.numRefundsDebit"
                    :amount="formatCurrency(device.refundsDebitCents / 100)"
                    amountTitle="Amount Refunded"
                  />
                </b-tooltip>
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p :id="`cash-refunds-${device.postgresDeviceUuid}`" class="dotted-underline">
                  {{ formatCurrency(device.refundsCashCents / 100) }}
                </p>
                <b-tooltip :target="`cash-refunds-${device.postgresDeviceUuid}`" triggers="hover" placement="right">
                  <DeviceSalesReportTooltip
                    :quantity="device.numRefundsCash"
                    :amount="formatCurrency(device.refundsCashCents / 100)"
                    amountTitle="Amount Refunded"
                  />
                </b-tooltip>
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p :id="`refunds-${device.postgresDeviceUuid}`" class="dotted-underline">
                  {{ formatCurrency(device.refundsCents / 100) }}
                </p>
                <b-tooltip :target="`refunds-${device.postgresDeviceUuid}`" triggers="hover" placement="right">
                  <DeviceSalesReportTooltip
                    :quantity="device.numRefunds"
                    :amount="formatCurrency(device.refundsCents / 100)"
                    amountTitle="Amount Refunded"
                  />
                </b-tooltip>
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p :id="`credit-sales-${device.postgresDeviceUuid}`" class="dotted-underline">
                  {{ formatCurrency(device.salesCreditCents / 100) }}
                </p>
                <b-tooltip :target="`credit-sales-${device.postgresDeviceUuid}`" triggers="hover" placement="right">
                  <DeviceSalesReportTooltip
                    :quantity="device.numOrdersCredit"
                    :amount="formatCurrency(device.salesCreditCents / 100)"
                    amountTitle="Amount Sold"
                    squareColorClass="bg-green-500"
                  />
                </b-tooltip>
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p :id="`debit-sales-${device.postgresDeviceUuid}`" class="dotted-underline">
                  {{ formatCurrency(device.salesDebitCents / 100) }}
                </p>
                <b-tooltip :target="`debit-sales-${device.postgresDeviceUuid}`" triggers="hover" placement="right">
                  <DeviceSalesReportTooltip
                    :quantity="device.numOrdersDebit"
                    :amount="formatCurrency(device.salesDebitCents / 100)"
                    amountTitle="Amount Sold"
                    squareColorClass="bg-green-500"
                  />
                </b-tooltip>
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p :id="`cash-sales-${device.postgresDeviceUuid}`" class="inline-block dotted-underline">
                  {{ formatCurrency(device.salesCashCents / 100) }}
                </p>
                <b-tooltip :target="`cash-sales-${device.postgresDeviceUuid}`" triggers="hover" placement="right">
                  <DeviceSalesReportTooltip
                    :quantity="device.numOrdersCash"
                    :amount="formatCurrency(device.salesCashCents / 100)"
                    amountTitle="Amount Sold"
                    squareColorClass="bg-green-500"
                  />
                </b-tooltip>
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p :id="`sales-${device.postgresDeviceUuid}`" class="inline-block dotted-underline">
                  {{ formatCurrency(device.salesCents / 100) }}
                </p>
                <b-tooltip :target="`sales-${device.postgresDeviceUuid}`" triggers="hover" placement="right">
                  <DeviceSalesReportTooltip
                    :quantity="device.numOrders"
                    :amount="formatCurrency(device.salesCents / 100)"
                    amountTitle="Amount Sold"
                    squareColorClass="bg-green-500"
                  />
                </b-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import { downloadFormattedCSV } from '@/lib/download-file';
import DownloadReportButton from '@/components/ui/DownloadReportButton';
import ReportServiceV2 from '@/lib/report-service-v2';
import DeviceSalesReportTooltip from '@/components/ui/DeviceSalesReportTooltip';

export default {
  components: {
    Alert,
    DownloadReportButton,
    DeviceSalesReportTooltip
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      downloadingReport: false
    };
  },
  async mounted() {
    await this.getReport();
  },
  computed: {
    salesReport() {
      return this.$store.state.deviceSalesReport.report;
    },
    loading() {
      return this.$store.state.deviceSalesReport.loading;
    },
    error() {
      return this.$store.state.deviceSalesReport.error;
    }
  },
  methods: {
    async getReport() {
      await this.$store.dispatch('getDeviceSalesReport', this.eventId);
    },
    async downloadReport() {
      this.downloadingReport = true;
      try {
        const data = await ReportServiceV2.retrieveDeviceSalesReport(this.eventId, {}, 'text/csv');
        downloadFormattedCSV(`device_sales_${Date.now()}.csv`, data);
      } catch (error) {
        const formattedError = this.parseError(error).message;
        this.$store.commit('SET_ERROR', formattedError);
      }
      this.downloadingReport = false;
    }
  }
};
</script>

<style lang="scss">
.dotted-underline {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}
</style>
