<template>
  <div class="flex items-center bg-green-600 py-2 pl-4 pr-2 rounded-full">
    <FontAwesomeIcon class="text-3xl text-white" :icon="['far', 'medal']" />
    <span class="text-white px-2">{{ winner.name }}</span>
    <span class="inline-block bg-white rounded-full p-2">{{ winner.ticketNumber }}</span>
  </div>
</template>

<script>
export default {
  props: {
    winner: {
      type: Object,
      required: true
    }
  }
};
</script>
