<template>
  <div class="inline mr-2 mb-2">
    <b-button
      title="Ends the raffle immediately"
      v-b-modal="'end-raffle-modal'"
      variant="outline-danger"
      size="sm"
      v-if="canEndRaffle && event.settings.allowInVenueSales"
    >
      <FontAwesomeIcon :icon="['far', 'ban']" />
      <span class="pl-2">End Early</span>
    </b-button>
    <b-modal
      :id="'end-raffle-' + modalId"
      title="End Raffle"
      v-model="modalShow"
      @ok="handleOk"
      no-close-on-backdrop
      size="m"
      body-class="position-static"
    >
      <b-form @submit.stop.prevent="onSubmit">
        <b-overlay :show="loading" no-wrap rounded="sm" />
        Are you sure you want to end this raffle?
        <div v-if="errorMessage" class="alert alert-danger">Failed to end the raffle: {{ errorMessage }}</div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import EventService from '@/lib/event-service-v2';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  props: {
    modalId: {
      type: String
    },
    event: {
      type: Object,
      default: () => {
        return {
          id: null
        };
      }
    }
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      id: null,
      loading: false,
      canEndRaffle: false
    };
  },
  async mounted() {
    this.canEndRaffle = await featureEnabled(LaunchDarklyKeys.EndRaffle);
  },
  methods: {
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      this.loading = true;
      try {
        await EventService.endRaffle(this.event.id);

        this.modalShow = false;
        this.$router.push({ name: 'Raffles' });
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
      this.loading = false;
    }
  }
};
</script>
