<template>
  <div>
    <b-button v-b-modal="'deactivate-raffle-modal'" variant="outline-danger" size="sm">
      <FontAwesomeIcon :icon="['far', 'circle-stop']" />
      <span class="pl-2">Deactivate</span>
    </b-button>
    <b-modal
      :id="'deactivate-raffle-' + modalId"
      title="Deactivate Raffle"
      v-model="modalShow"
      @ok="handleOk"
      no-close-on-backdrop
      size="m"
      body-class="position-static"
    >
      <b-form @submit.stop.prevent="onSubmit">
        <b-overlay :show="loading" no-wrap rounded="sm" />
        Are you sure you want to deactivate this raffle?
        <div v-if="errorMessage" class="alert alert-danger">Failed to deactivate the raffle: {{ errorMessage }}</div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import EventService from '@/lib/event-service-v2';

export default {
  props: {
    modalId: {
      type: String
    },
    event: {
      type: Object,
      default: () => {
        return {
          id: null
        };
      }
    }
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      id: null,
      loading: false
    };
  },
  methods: {
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      this.loading = true;
      try {
        await EventService.deactivateEvent(this.event.id);

        this.modalShow = false;
        this.$router.go();
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
      this.loading = false;
    }
  }
};
</script>
